import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ViewMembers from "@mui/icons-material/Visibility";
import { Tooltip } from "@mui/material";

const ReportTable = ({ header, body }) => {
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  const history = useHistory();

  useEffect(() => {
    setTableHeader(header);
    setTableBody(body);
  }, [header, body]);

  const handleRunReport = (viewLink) => {
    history.push("/display-report", { viewLink });
  };

  return (
    <div className="w-full rounded-lg shadow">
      <table className="w-full">
        <thead className="bg-gray-50 border-b-2 border-gray-200">
          <tr>
            {tableHeader?.map((header, index) => {
              return (
                <th
                  key={index}
                  className="p-3 text-sm font-semibold tracking-wider text-left"
                >
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-100 whitespace-nowrap">
          {tableBody?.map((data, index) => {
            return (
              <tr key={index}>
                <td className="p-3 text-sm text-gray-700">{data.reportName}</td>
                <td className="p-3 text-sm text-gray-700">
                  {data.description}
                </td>
                <td className="p-3 text-sm text-gray-700">
                  <button
                    className="inline-block"
                    onClick={() => handleRunReport(data.viewLink)}
                  >
                    <Tooltip title="View Report" arrow placement="top">
                      <svg
                        className="w-6 h-6"
                        style={{ color: "#2288EE", fill: "#2288EE" }}
                        viewBox="0 0 20 20"
                      >
                        <ViewMembers />
                      </svg>
                    </Tooltip>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
