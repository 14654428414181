import React, { useEffect, useState } from "react";
import DragList from "react-list-drag-and-drop/lib/RLDD";
import { shuffleArray } from "../../../../lib/helpers";

const Order = (props) => {
  const { id, answer, setAnswer, options } = props;

  useEffect(() => {
    shuffleArray(options);
    setAnswer(options);
  }, [options]);

  useEffect(() => {
    if (answer) {
      handleSubmitResponse();
    }
  }, [answer]);

  const handleDragListChange = (newOrder) => {
    setAnswer(newOrder);
  };

  const handleSubmitResponse = async () => {
    const order = {};
    let counter = 1;
    for (const option of answer) {
      order[counter] = option.mcq_option.id;
      counter++;
    }
    const orderResponse = {
      id,
      type: "order",
      answer: order,
    };
    props.sendResponse(orderResponse);
  };

  if (!answer) return null;

  return (
    <div>
      <div>
        <DragList
          items={answer? answer: []}
          itemRenderer={(option) => {
            return (
              <div className="z-10 option body-medium-1 p-6 my-4">
                {option?.mcq_option?.option_text}
              </div>
            );
          }}
          onChange={handleDragListChange}
        />
      </div>
    </div>
  );
};

export default Order;
