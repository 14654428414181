import "./index.css";
const LinearButton = ({
  type = "primary",
  text = "button",
  disabled = false,
  size = "large",
  onClick = () => {},
  width = "",
  height = "",
}) => {
  const className = `${type} ${size}`;

  let styleName = {};

  if (width !== "") {
    styleName.width = width;
  }

  if (height !== "") {
    styleName.height = height;
  }

  return (
    <button
      className={className}
      style={styleName}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default LinearButton;
