import React from "react";
import { Switch, Route } from "react-router-dom";
import AdminAddQuestion from "./Add";

const AdminQuestionRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/admin/question/add/:activity_id?"
        component={AdminAddQuestion}
      />
    </Switch>
  );
};

export default AdminQuestionRoutes;
