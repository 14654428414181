import learn_anywhere from "../../assests/learn_anywhere.svg";
import "./index.css";

const WIPPage = ({ location }) => {
  return (
    <>
      <div className="flex flex-col sm:flex-row h-screen gap-8 mt-2 md:mt-0 items-center md:justify-center">
        <div style={{ width: "75%" }}>
          <img alt="learn_anywhere" src={learn_anywhere} />
        </div>
        <div style={{ width: "75%" }}>
          <div>
            <h1 className="title highlight mb-8">Work In Progress</h1>
            <h3 className="subtitle">
              This URL <code className="highlight">{location.pathname}</code> is
              currently under developement. Do Check us out later or you could
              go back to{" "}
              <a href="/" className="highlight">
                Home
              </a>
              .
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default WIPPage;
