import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { API } from "../../../../api";
import LinearButton from "../../../../components/Button";

const AdminChallengeList = () => {
  const [challengeList, setChallengeList] = useState();
  const [goalList, setGoalList] = useState();
  const [selectedGoal, setSelectedGoal] = useState();

  useEffect(() => {
    async function getGoalList() {
      const { data } = await API.get("goal", "all");
      setGoalList(data);
    }
    getGoalList();
  }, []);

  const _handleChange = (e) => {
    setSelectedGoal(e.target.value);
  };

  const _handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedGoal !== null) {
      const { data } = await API.get(
        "challenge/list",
        `?goal_id=${selectedGoal}`
      );
      setChallengeList(data);
    }
  };
  return (
    <div>
      <div>
        <select name="goal_id" className="p-4" onChange={_handleChange}>
          {goalList?.map((goal, index) => {
            return (
              <option key={index} value={goal.id}>
                {goal.name}
              </option>
            );
          })}
        </select>
        <LinearButton text="Filter" onClick={_handleSubmit} />
      </div>
      <div>
        {challengeList?.map((challenge, index) => {
          return (
            <div key={index} className="p-4 shadow-xl m-4 ">
              <div className="text-2xl">{challenge.challenge?.name}</div>
              <div>
                <span className="text-xl text-blue-700">Code:</span>{" "}
                {challenge.challenge?.code}
              </div>
              <div>
                <span className="text-xl text-blue-700">Description:</span>{" "}
                {challenge.challenge?.description}
              </div>
              <div>
                <span className="text-xl text-blue-700">
                  Calender Required:
                </span>{" "}
                {challenge.challenge?.calender_required ? "True" : "False"}
              </div>
              <div>
                <span className="text-xl text-blue-700">
                  Time Required in minutes:
                </span>{" "}
                {challenge.challenge?.time_required_in_minutes}
              </div>
              <div>
                <span className="text-xl text-blue-700">Reward Points:</span>{" "}
                {challenge.challenge?.reward_points}
              </div>
              <div>
                <span className="text-xl text-blue-700">Type:</span>{" "}
                {challenge.challenge?.type}
              </div>
              <div>
                <span className="text-xl text-blue-700">Status:</span>{" "}
                {challenge.active ? "Active" : "Inactive"}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminChallengeList;
