import React, { useEffect } from "react";
import { useStoreState } from "easy-peasy";
import Routes from "./routes";
import { SWRConfig } from "swr";
import { API } from "./api";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { initializeMessaging } from "./firebase";

require("dotenv").config();

function App() {
  // initializeMessaging();
  // useEffect(async () => {
  //   const gapi = window.gapi;
  //   await gapi.load("auth2");
  //   await gapi.load("client:auth2");
  // }, []);

  return (
    <>
      <SWRConfig
        value={{
          fetcher: async (url) => {
            const { data } = await API.SWRGet(url);
            return data;
          },
        }}
      >
        <Routes />
      </SWRConfig>
    </>
  );
}

export default App;
