import React, { useState } from "react";
import FlashCardsJournalComponent from "../../FlashCard";
import Tips from "../../Tips";
import Challenge from "../../Challenge";
import Notes from "../../Notes";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import TipIcon from "@mui/icons-material/BookmarkBorderOutlined";
import NoteIcon from "@material-ui/icons/MenuOutlined";
import TodoIcon from "@mui/icons-material/TaskAltOutlined";
import ChallengeIcon from "@mui/icons-material/SportsScoreOutlined";

import { useHistory } from "react-router-dom";

import profile from "../../../../assests/icons/profile/profile.svg";
import team from "../../../../assests/icons/profile/team.svg";
import admin from "../../../../assests/icons/profile/admin.svg";
import settings from "../../../../assests/icons/profile/settings.svg";

import selected_profile from "../../../../assests/icons/profile/selected_profile.svg";
import selected_team from "../../../../assests/icons/profile/selected_team.svg";
import selected_admin from "../../../../assests/icons/profile/selected_admin.svg";
import selected_settings from "../../../../assests/icons/profile/selected_settings.svg";

const MENU_OPTIONS = [
  {
    index: 0,
    text: "Tips",
    value: "tip",
    img: profile,
    selected_img: selected_profile,
  },
  {
    index: 1,
    text: "FlashCard",
    value: "flashcard",
    img: team,
    selected_img: selected_team,
  },
  {
    index: 2,
    text: "Home",
    value: "home",
  },
  {
    index: 3,
    text: "Challenge",
    value: "challenge",
    img: admin,
    selected_img: selected_admin,
  },
  {
    index: 4,
    text: "Notes",
    value: "note",
    img: settings,
    selected_img: selected_settings,
  },
];

export default function JournalScreenMobile(props) {
  const [component, setComponent] = useState("tip");
  const [selectedButton, setSelectedButton] = useState(0);
  const history = useHistory();

  const displayComponent = () => {
    if (component === "tip") {
      return <Tips />;
    } else if (component === "flashcard") {
      return <FlashCardsJournalComponent />;
    } else if (component === "challenge") {
      return <Challenge />;
    } else if (component === "note") {
      return <Notes />;
    } else if (component === "home") {
      history.push("/user/dashboard");
      return null;
    }
  };

  const handleNavBarStyle = (index) => {
    if (index === selectedButton && index !== 2) {
      return {
        backgroundColor: "#E9F3FD",
        color: "#2288EE",
        borderRadius: "10px",
      };
    }
    if (index === 2) {
      return {
        height: 55,
        width: 130,
        marginLeft: "4px",
        marginRight: "4px",
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        backgroundColor: "var(--prussian-blue)",
        borderRadius: "50%",
      };
    }
  };

  const returnIcon = (name) => {
    switch (name) {
      case "tip":
        return <TipIcon />;
      case "flashcard":
        return <TodoIcon />;
      case "note":
        return <NoteIcon />;
      case "challenge":
        return <ChallengeIcon />;
      default: return <></>;
    }
  };

  return (
    <div>
      <div>{displayComponent()}</div>
      <div className="w-full mb-24">
        <section className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
          <div className="flex justify-between p-4">
            {MENU_OPTIONS.map((option, index) => {
              return (
                <div
                  key={index}
                  className="w-full justify-center inline-block text-center pt-2 pb-1"
                  onClick={() => {
                    setComponent(option.value);
                    setSelectedButton(index);
                  }}
                  style={handleNavBarStyle(index)}
                  //   style={
                  //     index === selectedButton
                  //       ? {
                  //           backgroundColor: "#E9F3FD",
                  //           color: "#2288EE",
                  //           borderRadius: "10px",
                  //         }
                  //       : null
                  //   }
                >
                  <div className="flex flex-col items-center">
                    {/* <img
                      src={
                        selectedButton === index && index !== 2
                          ? option.selected_img
                          : option.img
                      }
                    /> */}
                    {returnIcon(option.value)}
                    {index === 2 && <HomeIcon />}
                    <span className="tab tab-explore block text-xs">
                      {option.text}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
}
