import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

export default function DisplayReport({ data, email }) {
  const visibleFields = Object.keys(data[0]);
  const reportData = data;
  // Calculating serial numbers and add them to each row
  const dataWithSno = reportData
    .map((row, index) => ({
      ...row,
      sno: index + 1,
      id: index,
      key: index,
    }))
    .filter((row) => row.Email !== email);

  const columns = [
    {
      field: "sno",
      headerName: "S.no",
      width: 100,
      headerClassName: () => "bg-gray-100 border-b-2 border-gray-200",
    },
    ...visibleFields.map((field) => ({
      field,
      headerName: field,
      flex: 1,
      headerClassName: () => "bg-gray-100 border-b-2 border-gray-200",
    })),
  ];

  const dataForGrid = {
    columns: columns,
    rows: dataWithSno, //Data with serial numbers
    rowLength: reportData.length,
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <DataGrid
          {...dataForGrid}
          keyField="key"
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </div>
  );
}
