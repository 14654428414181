import React, { useEffect } from "react";
import Header from "../../legacy/Header";
import { useHistory } from "react-router-dom";
import "./index.css";

const Home = () => {
  const history = useHistory();
  useEffect(()=> {
    history.push("/user/login");
  }, [history])
  return (
    <>
      {/* <main className="bg-color-main">
        <div>
          <h1 className="title">Learn Better at Work</h1>
        </div>
        <div>
          <p className="subtitle">
            Increase the learning retention and application ability of your
            teams.
          </p>

          <p className="subtitle">
            Fun and Active. No Boring Videos. No Irrelevant Certifications.
          </p>

          <p className="subtitle">
            Personalized. Gamified. The Machine Learns!
          </p>
        </div>
      </main> */}
    </>
  );
};

export default Home;
