import React, { useState, useEffect } from "react";
import { API } from "../../../api";
import DailyProgressChart from "../../DailyProgressChart";

const DailyProgress = () => {
  const [userPoints, setUserPoints] = useState([]);

  useEffect(() => {
    async function getUserPoints() {
      let { data, status } = await API.get("user-data", "week-points");
      if (status === 200) setUserPoints(data);
    }
    getUserPoints();
  }, []);

  return <DailyProgressChart data={userPoints} />;
};

export default DailyProgress;
