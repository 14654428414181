import { TextField, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./index.css";

const FillBlanks = (props) => {
  const [answer, setAnswer] = useState();
  // const { id } = props;

  const _handleChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleFillBlanksResponse = async () => {
    const fillBlanksData = {
      answer,
      type: "fill_blanks",
    };
    props.sendResponse(fillBlanksData);
  };

  useEffect(() => {
    handleFillBlanksResponse();
  }, [answer]);

  return (
    <div className="fill_blank_area mt-8">
      <TextField
        name="answer"
        onChange={_handleChange}
        className="text_area"
        inputProps={{ style: { fontSize: "25px" } }}
      />
    </div>
  );
};

export default FillBlanks;
