import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../../404RedirectPage";
import AdminAddTrack from "./Add";
import AdminTrackList from "./List";

const AdminTrackRoutes = () => {
  return (
    <Switch>
      <Route exact path="/admin/track/add" component={AdminAddTrack} />
      <Route exact path="/admin/track/list" component={AdminTrackList} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default AdminTrackRoutes;
