import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";

const DailyProgressChart = (props) => {
  const data = [
    { day: "Su", Points: 0 },
    { day: "Mo", Points: 0 },
    { day: "Tu", Points: 0 },
    { day: "We", Points: 0 },
    { day: "Th", Points: 0 },
    { day: "Fr", Points: 0 },
    { day: "Sa", Points: 0 },
  ];
  const [chartData, setChartData] = useState(data);

  const { data: propsData } = props;

  useEffect(() => {
    if (propsData) {
      for (const pointData of propsData) {
        const newDate = new Date(pointData.date).getDay() - 1; // in deployment, one day is shown ahead
        const points = pointData.points_earned;
        if (newDate >= 0) data[newDate].Points = points;
      }
      setChartData(data);
    }
  }, [propsData]);

  return (
    <div className="bg-white p-4 pb-12 column h-72 body-large">
      <div className="flex mb-2 flex-row justify-between">
        <h6>Daily Progress</h6>
      </div>
      <ResponsiveBar
        data={chartData}
        keys={["Points"]}
        indexBy="day"
        groupMode="grouped"
        axisLeft={{ tickValues: 4 }}
        gridYValues={4}
        label={false}
        margin={{ right: 30, left: 30, top: 10, bottom: 24 }}
        padding={0.8}
        theme={{
          fontSize: 14,
          grid: {
            line: {
              stroke: "#eeeeee",
              strokeWidth: 1,
            },
          },
          axis: {
            ticks: {
              line: { stroke: "#eee" },
            },
          },
        }}
        colors={["var(--blue-de-france)"]}
        animate={false}
      />
    </div>
  );
};

export default DailyProgressChart;
