import React, { useEffect, useState } from "react";
import LinearTextArea from "../../../components/TextArea/index";

const FillBlanks = (props) => {
  const {id, answer="", setAnswer=()=>{}, sendResponse=()=>{}, selected="", reviewMode} = props;
  const _handleChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleFillBlanksResponse = async () => {
    // This is responsible for sending our responses to challengeContainer
    const fillBlanksData = {
      answer,
      type: "textbox",
    };
    sendResponse(fillBlanksData);
  };

  useEffect(() => {
    // We will check if user has responsed for particular question through selected prop
    setAnswer(selected);
  }, [id]);

  useEffect(() => {
    handleFillBlanksResponse();
  }, [answer]);

  return (
    <div className="fill_blank_area mt-8">
      <div className="flex flex-col w-full md:w-3/4 lg:w-1/2 flex-wrap gap-8 my-4  p-4 py-4 text-center" 
      style={{
      background: "#FFFFFF",
      boxShadow: "rgba(0, 51, 102, 0.1)",
      borderRadius: "10px",
      }}>
        <LinearTextArea
          disabled={reviewMode}
          value={reviewMode? selected: answer}
          placeholder="Write your answer"
          onChange={_handleChange}
          rows={4}
          columns={40}
        />
        </div>
    </div>
  );
};

export default FillBlanks;
