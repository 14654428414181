import React, { useEffect, useState } from "react";
import { API } from "../../../api";
import "./index.css";
import { useHistory } from "react-router-dom";
import background_hill from "../../../assests/icons/background_hill.svg";
import goal_target from "../../../assests/icons/goal_target.svg";
import { CircularProgress } from "@material-ui/core";
import NewModal from "../../../components/NewModal";
import useScreenSize from "../../../hooks/useScreenSize";

const AssessmentResult = (props) => {
  const [assessmentResult, setAssessmentResult] = useState();
  const [goalList, setGoalList] = useState();
  const [chosenGoal, setChosenGoal] = useState();

  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [showModalProgress, setShowModalProgress] = useState(true);
  const [showModalCloseButton, setShowModalCloseButton] = useState(false);

  const { isMobile } = useScreenSize();
  const { program_type } = props.location.state
  const { program_id } = props.match.params;
  const history = useHistory();

  useEffect(() => {
    async function getAssessmentResult() {
      const { data } = await API.get(
        "strapi-assessment/assessment-result",
        program_id
      );
      setAssessmentResult(data);
      setChosenGoal(data.goal.id);
    }
    async function getGoalList() {
      const { data } = await API.get("goal/program", program_id);
      setGoalList(data);
      if (program_type === "sprint") {
        setChosenGoal(data[0]?.id);
      }
  
    }

    console.log({ program_type });

    if (program_type === "program") {
      getAssessmentResult();
    }
    getGoalList();

  }, []);

  const toggleChosen = (id) => {
    setChosenGoal(id);
  };

  const _handleSubmit = async () => {
    setModalText("Generating your personalized learning plan...");
    setShowModalCloseButton(false);
    setShowModalProgress(true);
    setShowModal(true);
    const { data, status } = await API.post(`goal/select/${chosenGoal}`);
    if (status === 200) {
      setShowModal(false);
      history.push("/user/dashboard");
    } else {
      setShowModalProgress(false);
      setModalText(data.error.message);
      setShowModalCloseButton(true);
    }
  };

  const SelectedGoalForProgramComponent = () => <>
    {isMobile ? (
      <h5 className="text-center mb-6">
        Based on your responses, we recommend that your goal for this
        program should be
      </h5>
    ) : (
      <h4 className="text-center mb-6">
        Based on your responses, we recommend that your goal for this
        program should be
      </h4>
    )}

    <div
      className="flex flex-row justify-center"
      onClick={() => toggleChosen(assessmentResult?.goal?.id)}
    >
      <button
        className="other-goal p-4 sm:w-1/3 w-4/5 flex flex-row gap-4"
        style={
          chosenGoal === assessmentResult?.goal?.id
            ? { border: "2px solid #2288ee" }
            : null
        }
      >
        <img src={goal_target} alt="Goal Target" />
        <h5>{assessmentResult?.goal?.name}</h5>
      </button>
    </div>
  </>;

  const SelectedGoalForSprintComponent = () => <>
    {isMobile ? (
      <h5 className="text-center mb-6">
        Our Recommended Goal for this
        program is
      </h5>
    ) : (
      <h4 className="text-center mb-6">
        Our Recommended Goal for this
        program is
      </h4>
    )}

    <div
      className="flex flex-row justify-center"
      onClick={() => toggleChosen(goalList[0]?.id)}
    >
      <button
        className="other-goal p-4 sm:w-1/3 w-4/5 flex flex-row gap-4"
        style={
          chosenGoal === goalList[0]?.id
            ? { border: "2px solid #2288ee" }
            : null
        }
      >
        <img src={goal_target} alt="Goal Target" />
        <h5>{goalList[0]?.name}</h5>
      </button>
    </div>
  </>;


  if ((program_type === "program" && !assessmentResult) || !goalList) return null;

  return (
    <div className="flex flex-col my-8">
      <div>
        {program_type === "program" ?
          <>
            <SelectedGoalForProgramComponent />
            {isMobile ? (
              <h5 className="text-center my-6">
                If you want to select a different goal, here our other options
              </h5>
            ) : (
              <h4 className="text-center my-6">
                If you want to select a different goal, here our other options
              </h4>
            )}

            {goalList?.map((goal, index) => {
              if (goal.id !== assessmentResult.goal_id)
                return (
                  <div
                    className="flex flex-row justify-center z-10"
                    key={index}
                    onClick={() => toggleChosen(goal.id)}
                  >
                    <button
                      className="other-goal p-4 sm:w-1/3 w-4/5 mb-6 flex flex-row gap-4"
                      style={
                        chosenGoal === goal.id
                          ? { border: "2px solid #2288ee" }
                          : null
                      }
                    >
                      <img src={goal_target} alt="Goal Target" />
                      <h5>{goal.name}</h5>
                    </button>
                  </div>
                );
              return null;
            })} </> :
          <>
            <SelectedGoalForSprintComponent />
          </>


        }

      </div>
      <div className="flex  flex flex-row justify-end mx-10">
        <button
          className="button-large finalize-button text-white py-4 px-8 md:absolute bottom-10 right-10 z-10"
          onClick={_handleSubmit}
        >
          Finalize Goal and Create my Personalized Learning plan
        </button>
      </div>
      <div>
        {showModal && (
          <NewModal
            body={
              <div className="flex flex-col gap-4 items-center justify-center">
                <div>{showModalProgress && <CircularProgress />}</div>
                <div className="text-center">
                  <h5>{modalText}</h5>
                </div>
              </div>
            }
            hasCloseButton={showModalCloseButton}
            onClose={() => setShowModal(false)}
          />
        )}
      </div>
      {!isMobile && (
        <img
          src={background_hill}
          className="w-full md:absolute bottom-0"
          style={{ zIndex: -10 }}
          alt="BackgroundHill"
        />
      )}
    </div>
  );
};

export default AssessmentResult;
