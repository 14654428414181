import { CircularProgress } from "@material-ui/core";
// import { useStoreActions } from "easy-peasy";
import React, { useState } from "react";
import { API } from "../../../api";
import learn_anywhere from "../../../assests/learn_anywhere.svg";
import { useHistory } from "react-router";
import Header from "../../../legacy/Header";
import PasswordField from "../../../components/PasswordField";
import Alert from "@material-ui/lab/Alert";
import LHS from "../../../components/LHS";
import LinearTextBox from "../../../components/TextBox";
import LinearButton from "../../../components/Button";

const ResetPassword = () => {
  const [resetData, setResetData] = useState({
    email: "",
    password: "",
    code: "",
  });

  const [alertVisible, toggleAlertVisibility] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [inProgress, toggleProgress] = useState(false);

  // const saveUser = useStoreActions((a) => a.user.update);
  const history = useHistory();

  const _handleChange = (e) => {
    toggleAlertVisibility(false);
    setResetData({ ...resetData, [e.target.name]: e.target.value });
  };
  const _handleSubmit = async () => {
    try {
      toggleProgress(true);
      const { data, status } = await API.post("user/reset-password", resetData);
      if (status === 200) {
        setAlertMessage(data.message);
        setAlertSeverity("success");
        toggleAlertVisibility(true);
        setTimeout(() => {
          history.push("/user/login");
        }, 2000);
      } else {
        setAlertMessage(data.error ? data.error.message : "Error Occured");
        setAlertSeverity(data.error ? "error" : "info");
        toggleAlertVisibility(true);
        toggleProgress(false);
      }
    } catch (e) {
      setAlertMessage("An error occured. Please try again later.");
      setAlertSeverity("error");
      toggleAlertVisibility(true);
      toggleProgress(false);
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "auto",
          width: "100%",
          height: "100vh",
        }}
      >
        <div style={{ width: "50%" }}>
          <LHS />
        </div>
        <div
          className="mx-auto h-screen justify-center flex flex-col"
          style={{}}
        >
          <h3>Reset Password</h3>
          <p style={{ marginBottom: "1.5rem" }}>
            A verification code has been sent to your email. Please check your
            email
          </p>

          <LinearTextBox
            label="Email"
            name="email"
            placeholder="Enter your email"
            onChange={_handleChange}
            style={{ marginBottom: "20px" }}
          />
          <LinearTextBox
            label="Code"
            name="code"
            placeholder="Enter the code"
            onChange={_handleChange}
            style={{ marginBottom: "20px" }}
          />
          <LinearTextBox
            label="Password"
            placeholder="Enter new password"
            name="password"
            _handleChange={_handleChange}
            style={{ marginBottom: "20px" }}
            type="password"
          />
          <LinearButton
            onClick={_handleSubmit}
            style={{ padding: "20px" }}
            disabled={inProgress}
            text={inProgress ? <CircularProgress /> : "Reset"}
          />

          {alertVisible && (
            <Alert style={{ marginTop: "1.5rem" }} severity={alertSeverity}>
              {alertMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
