import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../../../api";

const AdminAllActivity = () => {
  const [activityList, setActivityList] = useState();
  const history = useHistory();

  useEffect(() => {
    async function getAllActivity() {
      const { data } = await API.get("activity", "all");
      setActivityList(data);
    }
    getAllActivity();
  }, []);

  return (
    <div>
      {activityList?.map((activity, index) => {
        return (
          <div key={index} className="p-4 shadow-xl m-4 ">
            <div className="text-2xl">{activity.name}</div>
            <div>
              <span className="text-xl text-blue-700">Code:</span>{" "}
              {activity.code}
            </div>
            <div>
              <span className="text-xl text-blue-700">Description:</span>{" "}
              {activity.description}
            </div>
            <div>
              <span className="text-xl text-blue-700">
                Reattempt after days:
              </span>{" "}
              {activity.reattempt_after_days}
            </div>
            <div>
              <span className="text-xl text-blue-700">
                Same day all correct bonus:
              </span>{" "}
              {activity.same_day_all_correct_bonus}
            </div>
            <div>
              <span className="text-xl text-blue-700">Same day bonus:</span>{" "}
              {activity.same_day_bonus}
            </div>
            <div>
              <span className="text-xl text-blue-700">All correct bonus:</span>{" "}
              {activity.all_correct_bonus}
            </div>
            <div>
              <span className="text-xl text-blue-700">Type:</span>{" "}
              {activity.type}
            </div>
            <div>
              <span className="text-xl text-blue-700">Reading Id:</span>{" "}
              {activity.reading_id || "None"}
            </div>
            <div className="text-xl text-blue-700">
              Questions under this activity{" "}
            </div>
            <div>
              {activity.questions?.map((question, index) => {
                return (
                  <div key={index}>
                    <span>{index + 1} </span>
                    {question.question?.stem}
                  </div>
                );
              })}
            </div>
            <div>
              <span className="text-xl text-blue-700">Status:</span>{" "}
              {activity.active ? "Active" : "Inactive"}
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push(`/admin/question/add/${activity.id}`)}
              style={{ padding: "20px" }}
            >
              Add questions
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default AdminAllActivity;
