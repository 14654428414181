import React, { useState } from "react";
import "./index.css";
import ContextCardIcon from "@mui/icons-material/AutoStories";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Carousel from "../Carousel";

const ContextCardRowBox = ({ context_cards }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "65%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <div
        onClick={handleOpen}
        className="context-card-box cursor-pointer flex"
      >
        <div>
          <svg
            className="w-5 h-5"
            style={{ color: "#2288EE", fill: "#2288EE" }}
            viewBox="0 0 20 20"
          >
            <ContextCardIcon />
          </svg>
        </div>

        <h6 className="context-card-text ml-4">
          {context_cards[0]?.context_card_display_name}
        </h6>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Carousel contextCards={context_cards} onClose={handleClose} />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default ContextCardRowBox;
