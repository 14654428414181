import { CircularProgress } from "@material-ui/core";
import LinearButton from "../../components/Button";
import LinearTextBox from "../../components/TextBox";

const PasswordContainer = ({
  handlePassword = () => {},
  handleConfirmPassword = () => {},
  _handlePasswordSubmit = () => {},
  handleOTP = () => {},
  inProgress = false,
  passContErrorMsg = "",
  passErrorMsg = "",
  confirmPassErrorMsg = "",
  otpErrMsg = "",
}) => (
  <div style={{ margin: "15.0625rem 11.625rem" }}>
    <h2>New Password</h2>
    <p className="margin-top-15 body-medium-1">Setup your new password.</p>
    <div style={{ marginTop: "2.1875rem" }}>
      <LinearTextBox
        label="Enter OTP"
        onChange={handleOTP}
        errorMessage={otpErrMsg}
        placeholder="Enter OTP"
      />
    </div>
    <div style={{ marginTop: "1.1875rem" }}>
      <LinearTextBox
        label="Password"
        onChange={handlePassword}
        errorMessage={passErrorMsg}
        placeholder="Enter Password Here"
        type="password"
      />{" "}
    </div>
    <div style={{ marginTop: "1.25rem" }}>
      <LinearTextBox
        label="Confirm New Password"
        onChange={handleConfirmPassword}
        errorMessage={confirmPassErrorMsg}
        placeholder="Confirm Password Here"
        type="password"
      />
    </div>
    <p className="text-box-errorText body-extra-medium-1 margin-top-22">
      {passContErrorMsg}
    </p>

    <div className="margin-top-22">
      <LinearButton
        type="primary"
        onClick={_handlePasswordSubmit}
        width="21.875rem"
        height="3rem"
        text={inProgress ? <CircularProgress /> : "Change Password"}
      />
    </div>
  </div>
);
export default PasswordContainer;
