import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "../../../api";
import clap from "../../../assests/icons/clap.svg";
import LinearButton from "../../../components/Button";
import useScreenSize from "../../../hooks/useScreenSize";
import Confetti from "react-confetti";

const DIFFICULTY_LEVELS = [
  { key: 0, text: "Very Easy", value: 0 },
  { key: 1, text: "Easy", value: 1 },
  { key: 2, text: "Neither Easy nor Difficult", value: 2 },
  { key: 3, text: "Difficult", value: 3 },
  { key: 4, text: "Very Difficult", value: 4 },
];

const ChallengeNewComplete = (props) => {
  const [userChallenge, setUserChallenge] = useState();

  const [challengeFeedback, setchallengeFeedback] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useScreenSize();
  const { challenge_id } = props.match.params;
  const goal_id = location.state?.goal_id;


  useEffect(() => {
    async function getUserChallenge() {

      const { data: userChallenge, status: ucStatus } = await API.get(
        `user-challenge/goal-id/${goal_id}/challenge-id`,challenge_id);
      if (userChallenge) {
        setUserChallenge(userChallenge);
      } else {
        const body = {
          date: new Date(),
          start_time: new Date().toISOString(),
          end_time: new Date(new Date().getTime() + 30 * 60000).toISOString(),
          goal_id
        }
        const { data, status } = await API.post(
          `challenge/schedule/${challenge_id}`,
          body
        );
        setUserChallenge(data);
      }
    }
    getUserChallenge();
  }, []);


  return (
    <div className="flex flex-col justify-center h-screen mx-4 text-center items-center">
      <img alt="clap" src={clap} />
      {isMobile ? (
        <h3 style={{ color: "#09B746" }}>CONGRATULATIONS!</h3>
      ) : (
        <h1 style={{ color: "#09B746" }}>CONGRATULATIONS!</h1>
      )}
      <div className="text-xl font-bold sm:subtitle">
        You have successfully completed the challenge
      </div>
      <div>
        <h5>How did you find this challenge?</h5>
        {DIFFICULTY_LEVELS.map((difficulty, index) => {
          return (
            <div
              key={index}
              className="body-small-1 py-1.5 px-4 flex flex-row items-center gap-1.5"
              style={{
                border: "1px solid #aaa",
                borderRadius: 10,
                margin: 10,
              }}
            >
              <input
                type="radio"
                name="difficulty_level"
                value={difficulty.value}
                onChange={() => {
                  setchallengeFeedback(difficulty.value);
                }}
                id={difficulty.key}
                className="cursor-pointer"
                style={{ height: "20px", width: "20px" }}
              />
              <label htmlFor={difficulty.key} className="cursor-pointer">
                {"  "}
                {difficulty.text}
              </label>
            </div>
          );
        })}
      </div>
      <button
        className="button-large text-white p-4 px-8 mt-4"
        style={{ background: "#003366", borderRadius: "10px" }}
        onClick={async () => {
          if (challengeFeedback !== null)
            await API.post(`challenge/respond/${challenge_id}`, {
              id: userChallenge.id,
              user_feedback: "",
              user_response: challengeFeedback,
              is_completed: true,
              goal_id
            });
          history.push("/user/dashboard");
        }}
      >
        Exit
      </button>
      <Confetti
        width={window.width}
        height={window.height}
        numberOfPieces={200}
      />
    </div>
  );
};

export default ChallengeNewComplete;