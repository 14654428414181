import { API } from "../../../../api";

const completeActivity = async ({
  history,
  activityStartTime,
  toggleButtonLoading,
  historyUrl,
  completionUrl,
}) => {
  toggleButtonLoading(true);
  const { data } = await API.post( `${completionUrl.path}`, completionUrl.body);
  toggleButtonLoading(false);
  history.push(`${historyUrl.path}`, {
    data,
    activityStartTime,
    activityEndTime: new Date().getTime(),
  });
};

export { completeActivity };
