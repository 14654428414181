import React, { useState } from "react";
import useSWR from "swr";
import { API } from "../../../../api";
import LinearButton from "../../../../components/Button";
import LinearTextBox from "../../../../components/TextBox";

export default function AdminAddTeam() {
  const { data: organizationList } = useSWR("organization/all");

  const [organization, setOrganization] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [teamAdminEmail, setTeamAdminEmail] = useState("");
  const [message, setMessage] = useState("");

  const _handleSubmit = async () => {
    if (
      teamName === "" ||
      teamDescription === "" ||
      organization === "" ||
      teamAdminEmail === ""
    ) {
      setMessage("Please fill all the values");
      return;
    }
    const { status } = await API.post("group/create-group", {
      name: teamName,
      description: teamDescription,
      organization_id: organization,
      group_admin_email: teamAdminEmail,
    });
    if (status === 200) {
      setMessage("Team added successfully");
    } else {
      setMessage("An error occured");
    }
  };

  return (
    <div
      className="h-screen flex flex-row justify-center items-center"
      style={{ backgroundColor: "var(--blue-de-france-10)" }}
    >
      <div className="py-4 px-8 rounded-xl shadow-lg flex flex-col gap-4 bg-white">
        <h4>Add new Team</h4>
        <div>
          <LinearTextBox
            name="name"
            onChange={(e) => {
              setTeamName(e.target.value);
              setMessage("");
            }}
            label="Team name"
            placeholder="Enter Team Name Here"
          />
        </div>
        <div>
          <LinearTextBox
            name="description"
            onChange={(e) => {
              setTeamDescription(e.target.value);
              setMessage("");
            }}
            label="Team description"
            placeholder="Enter Team Description Here"
          />
        </div>
        <div>
          <LinearTextBox
            name="admin_email"
            onChange={(e) => {
              setTeamAdminEmail(e.target.value);
              setMessage("");
            }}
            label="Team admin email"
            placeholder="Enter Team Admin Email Here"
          />
        </div>
        <div className="flex flex-col w-full sm:w-2/5">
          <label className="body-small-1">Organization</label>
          <select
            onChange={(e) => {
              setOrganization(e.target.value);
              setMessage("");
            }}
            name="organization"
            className="input-box body-small-1"
          >
            {organizationList &&
              [" ", ...organizationList]?.map((organization, index) => {
                return (
                  <option value={organization.id} key={index}>
                    {organization.name}
                  </option>
                );
              })}
          </select>
        </div>
        <LinearButton text="Add Team" onClick={_handleSubmit} />
        {message}
      </div>
    </div>
  );
}
