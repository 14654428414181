import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../../404RedirectPage";
import AdminAddTopic from "./Add";
import AdminTopicList from "./List";

const AdminTopicRoutes = () => {
  return (
    <Switch>
      <Route exact path="/admin/topic/add" component={AdminAddTopic} />
      <Route exact path="/admin/topic/list" component={AdminTopicList} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default AdminTopicRoutes;
