import React, { useEffect, useState } from "react";
import { API } from "../../../api";
import { useHistory } from "react-router";
import { Button, TextField } from "@material-ui/core";

const GoalStaticSchedule = () => {
  const [staticSchedule, setStaticSchedule] = useState();
  const [taskData, setTaskData] = useState();
  const history = useHistory();

  async function getStaticSchedule() {
    const { data } = await API.get("static-schedule/goal-schedule", 16);
    setStaticSchedule(data);
    console.log(data);
  }

  useEffect(() => {
    getStaticSchedule();
  }, [taskData]);

  const _goToTask = (task) => {
    const isActivity = !!task.activity;
    if (isActivity) {
      history.push("/admin/get-activity");
    }
  };

  const _handleChange = (e) => {
    setTaskData({ ...taskData, [e.target.name]: e.target.value });
    console.log(taskData);
  };

  return (
    <div className="">
      <div className=" flex justify-center items-center">
        <div className="shadow-xl p-36 w-1/2">
          <div className="flex flex-row justify-between">
            <div>Day </div>
            <div>Task</div>
          </div>
          {staticSchedule?.map((task, index) => {
            return (
              <div key={index}>
                <div className="flex flex-row justify-between">
                  <div>{task.day_id}</div>
                  <div>
                    <button
                      className="p-4 mb-2 bg-blue-200 w-48 rounded-xl"
                      onClick={() => _goToTask(task)}
                    >
                      {task.activity
                        ? task.activity?.name
                        : task.challenge?.name}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="bg-blue-200 bottom-0">
        <TextField label="Day Number" onChange={_handleChange} name="day_id" />
        <TextField
          label="Activity ID"
          onChange={_handleChange}
          name="activity_id"
        />
        <TextField
          label="Challenge ID"
          onChange={_handleChange}
          name="challenge_id"
        />
        <Button></Button>
      </div>
    </div>
  );
};

export default GoalStaticSchedule;
