import { CircularProgress } from "@material-ui/core";
import LinearButton from "../../components/Button";
import LinearTextBox from "../../components/TextBox";

const EmailContainer = ({
  handleEmail = () => {},
  emailErrorMsg = "",
  _handleEmailSubmit = () => {},
  inProgress = false,
  alertMessage = "",
}) => (
  <div style={{ margin: "15.0625rem 11.625rem" }}>
    <h3>Forgot Password</h3>
    <p className="margin-top-15 body-medium-1">
      Enter your account email below and we'll send you a link to reset your
      password.
    </p>
    <div style={{ marginTop: "2.1875rem" }}>
      <LinearTextBox
        label="Email Address"
        onChange={handleEmail}
        errorMessage={emailErrorMsg}
        placeholder="Enter your email address "
      />
    </div>
    <div className="margin-top-22">
      <LinearButton
        type="primary"
        onClick={_handleEmailSubmit}
        disabled={inProgress}
        width="21.875rem"
        height="3rem"
        text={inProgress ? <CircularProgress /> : "Reset Password"}
      />
    </div>
    <p className="body-medium-1 text-box-errorText">{alertMessage}</p>

    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "1rem",
        justifyContent: "space-between",
      }}
    >
      <p className="body-small-1">
        Back to{" "}
        <a style={{ color: "var(--link-color)" }} href="/user/login">
          Login
        </a>
      </p>
      {/* <a style={{ color: "var(--main-button-color)" }} href="/user/signup">Not Registered? SignUp</a> */}
    </div>
  </div>
);

export default EmailContainer;
