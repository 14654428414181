import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import linear_logo from "../../../assests/images/linear_logo.png";
import linear_text from "../../../assests/images/linear_text.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import book_stack from "../../../assests/icons/daily_challenge.svg";
// import { API } from "../../api";
import { useLocation } from "react-router";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Link, NavLink } from "react-router-dom";
import { useOnClickOutside } from "../../../hooks/useClickOutside";

const AdminHeader = () => {

  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const admin = useStoreState((s) => s.admin.data);
  const deleteAdmin = useStoreActions((a) => a.admin.delete);
  const location = useLocation();
  const adminEmail = localStorage.getItem("email");
  
  const adminMenuRef = useRef();
  useOnClickOutside(adminMenuRef, () => setShowAdminMenu(false));

  const logoutAdmin = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    deleteAdmin();
  };

  const headerNav = [
    {id:0,
    name:"Customer",
    path:"/admin/customer",
    },
    {id:1,
      name:"Curriculum",
      path:"/admin/curriculum",
    },
  ]

  return (

    <div
      className="flex flex-row flex-wrap justify-center items-center md:justify-between  w-full"
      style={{ backgroundColor: "#003366" }}
    >
      <Link to="/admin/customer">
        <div className="flex flex-row px-4">
          <img alt="Linear Logo" src={linear_logo} className="h-16" />
          <img alt="Linear Text" src={linear_text} className="h-16" />
        </div>
      </Link>

        

      <div className="body-small-1 flex flex-row p-4 items-center text-white my-auto gap-4">
        {!adminEmail && (
          <div>
            <Link to="/admin/login">Log in</Link>
          </div>

        )}
        

        {adminEmail && !location.pathname.split("/").includes("activity") && (
          <div className="flex flex-row items-center my-auto gap-4">

            {headerNav.map((elem, index)=>{
              // return <Link to={elem.path} key={index}>{elem.name}</Link>
              return (<NavLink to={elem.path} key={index} className="link" >
                <div className="link_text">{elem.name}</div>
              </NavLink>)
            })}
            
            <div>
              <div
                onClick={() => {
                  setShowAdminMenu(true);
                }}
                className="cursor-pointer flex flex-row justify-center gap-2 items-center"
              >
                
                {adminEmail}
                {showAdminMenu ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
              {showAdminMenu && (
                <div
                  ref={adminMenuRef}
                  className="absolute right-18 text-black bg-white w-36 rounded-xl"
                  onClick={() => {
                    setShowAdminMenu(false);
                  }}
                >
                
                  <Link to="/admin/login">
                    <div className="p-4 cursor-pointer" onClick={logoutAdmin}>
                      Logout
                    </div>
                  </Link>
                </div>
              )}
            </div>

          </div>
        )}
      </div>
    </div>
  );

};

export default AdminHeader;
