import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import background_hill from "../../../assests/icons/background_hill.png";
import CancelIcon from "@material-ui/icons/Clear";

import "./index.css";
import MCQ from "../../Activity/StrapiGetActivity/MCQ";
import FillBlanks from "../../Activity/StrapiGetActivity/FillBlanks";
import Order from "../../Activity/StrapiGetActivity/Order";
import { API } from "../../../api";
import LinearButton from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import ProgressBar from "../../../components/Activity/ProgressBar";
import useScreenSize from "../../../hooks/useScreenSize";
import useActivityState from "../../Activity/StrapiGetActivity/activityData";
import FlashCard from "../../ChallengeNew/FlashCard";

export default function GetRevision(props) {
  const [activity, setActivity] = useState();
  const [questionList, setQuestionList] = useState([]);
  const [reading, setReading] = useState();
  const [readingShow, setReadingShow] = useState(false);
  const [sequence, setSequence] = useState(1);
  const [maxSequence, setMaxSequence] = useState(1);
  const [questionResponse, setQuestionResponse] = useState();
  const [bgcolor, setBgColor] = useState("#FFFFFF");
  const [message, setMessage] = useState("");
  const [snippetShow, setSnippetShow] = useState(false);
  const [response, setResponse] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [isLoading, toggleLoading] = useState(true);
  const [showQuit, setShowQuit] = useState(false);
  const activityBodyEndRef = useRef();
  const activityBodyStartRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const { activityState, addNewResponse } = useActivityState();
  const activityStartTime = new Date().getTime();
  const [answer, setAnswer] = useState();


  const { isMobile } = useScreenSize();

  const revision_id = location.state?.revision_id;
  const goal_id = location.state?.goal_id;

  useEffect(() => {
    const expiry = localStorage.getItem("expiry");
    const now = new Date();
    const token = localStorage.getItem("token");
    if (!token || !expiry || new Date(expiry).getTime() <= now.getTime()) {
      history.replace("/user/login");
    }
  }, []);

  const scrollToBottom = () => {
    if (message)
      activityBodyEndRef.current?.scrollIntoView({ behavior: "smooth" });
    else {
      activityBodyStartRef.current?.scrollIntoView({ behaviour: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [message]);

  const getResponse = (response) => {
    setQuestionResponse(response);
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await API.get(
        "revision/get-questions",
        `${goal_id}/${revision_id}`
      );
      //   if (data.reattempt === false) {
      //     history.push(`/activity/review/${activity_id}`, {
      //       goal_id: goal_id,
      //     });
      //   }
      if (data.reading) {
        setReading(data.reading);
        setReadingShow(true);
      }
      setQuestionList(data);
      setActivity(data);
      setMaxSequence(data.length);
      toggleLoading(false);
    }
    fetchData();
  }, []);

  const _nextQuestion = async (id) => {
    if (sequence < maxSequence) setSequence(sequence + 1);
  };

  const submitActivity = async () => {
    const { data } = await API.post(`revision/complete/${revision_id}`, {
      goal_id,
    });
    history.push("/revision/complete", {
      data,
      reading,
      activityStartTime,
      activityEndTime: new Date().getTime(),
    });
  };

  const moveForward = (e) => {
    if (e.target.textContent.trim() === "Next") {
      setBgColor("#FFFFFF");
      setMessage();
      setResponse();
      _nextQuestion();
      setSnippetShow(false);
    } else if (e.target.textContent.trim() === "Finish") {
      setMaxSequence(maxSequence - 1);
      submitActivity();
      setDisableButton(true);
      e.target.textContent = "Finish";
    }
  };

  const handleSubmit = async (e) => {
    questionResponse["revision_id"] = revision_id;
    questionResponse["goal_id"] = goal_id;
    questionResponse["question_id"] = questionList[sequence - 1].question_id;
    toggleLoading(true);
    if (e.target.textContent.trim() === "Check") {
      setDisableButton(true);
      if (questionList[sequence - 1]?.question?.type === "flashcard") {
        moveForward(e);
        return;
      }
      const { data, status } = await API.post(
        "revision/response",
        questionResponse
      );
      setResponse(data);
      setSnippetShow(true);

      addNewResponse({
        id: questionList[sequence - 1].question_id,
        correctAnswer: data?.correctAnswer,
        isUserCorrect: data?.isUserCorrect,
        comment: questionList[sequence - 1]?.question?.comment,
      });

      if (data.isUserCorrect) {
        setBgColor("#0ACC81");
        setMessage("CORRECT ANSWER");
      } else {
        setBgColor("#CC3414");
        setMessage("INCORRECT ANSWER");
      }
      setDisableButton(false);
    } else if (e.target.textContent.trim() !== "Finish") {
      moveForward(e);
    } else {
      moveForward(e);
    }
    toggleLoading(false);
  };

  const displayQuestion = (type) => {
    if (type === "mcq")
      return (
        <MCQ
          setAnswer={setAnswer}
          answer={answer}
          options={questionList[sequence - 1].question?.components}
          id={questionList[sequence - 1].question_id}
          sendResponse={getResponse}
          isUserCorrect={response?.isUserCorrect}
          correctAnswer={response?.correctAnswer}
        />
      );
    else if (type === "fill_blanks") {
      return (
        <FillBlanks
          setAnswer={setAnswer}
          answer={answer}
          sendResponse={getResponse}
          id={questionList[sequence - 1].question_id}
        />
      );
    } else if (type === "order") {
      return (
        <Order
          setAnswer={setAnswer}
          answer={answer}
          id={questionList[sequence - 1].question.id}
          options={questionList[sequence - 1].question?.components}
          sendResponse={getResponse}
        />
      );
    } else if (type === "flashcard") {
      return (
        <>
          <FlashCard
            image={questionList[sequence - 1].question?.question_image_url}
            id={questionList[sequence - 1].question?.id}
          />
        </>
      );
    }
    // } else if (type === "match") {
    //   const optionList = activity.questions[sequence - 1].options;
    //   const optionKeys = optionList.filter((option) => {
    //     return option.is_key;
    //   });
    //   const optionValues = optionList.filter((option) => {
    //     return !option.is_key;
    //   });
    //   return (
    //     <Match
    //       id={activity.questions[sequence - 1].id}
    //       optionKeys={optionKeys}
    //       optionValues={optionValues}
    //       sendResponse={getResponse}
    //     />
    //   );
    // }
  };

  if (!revision_id || !questionList) return null;
  if (!goal_id) return null;

  return (
    <div className="w-11/12 sm:w-2/3 flex flex-col gap-4 mx-auto">
      <div className="flex flex-row justify-center items-center">
        <ProgressBar
          bgcolor={"#ef6c00"}
          completed={parseInt(((sequence - 1) / maxSequence) * 100)}
        />
        <CancelIcon
          className="cursor-pointer"
          onClick={() => {
            setShowQuit(true);
          }}
        />
      </div>
      <div
        className="scroll"
        style={{
          zIndex: 100,
          overflowY: "scroll",
          scrollBehavior: "smooth",
          maxHeight: "65vh",
        }}
      >
        <div ref={activityBodyStartRef}></div>
        <div style={{ whiteSpace: "pre-wrap" }}>
          {isMobile ||
            questionList[sequence - 1]?.question?.stem?.split(" ").length > 20 ? (
            <h6 className="mb-4 text-justify">
              {questionList[sequence - 1]?.question?.stem}
            </h6>
          ) : (
            <h5 className="text-center ">
              {questionList[sequence - 1]?.question?.stem}
            </h5>
          )}

          <div style={{ marginBottom: "15px" }}>
            {displayQuestion(questionList[sequence - 1]?.question?.type)}
          </div>
        </div>
        {questionList[sequence - 1]?.question?.type === "flashcard" && (
          <div> {questionList[sequence - 1]?.question?.comment}</div>
        )}

        <div
          className="body-medium-2 z-10 "
          style={{
            display: snippetShow ? "block" : "none",
            background: "#FDF9F0",
            overflow: "auto",
            height: "auto",
          }}
        >
          <div
            className="quarter-circle-top-right z-10"
            style={{
              backgroundColor: bgcolor,
            }}
          ></div>
          <div className="md:pr-24 md:pl-16 ">
            <div className="subtitle text-center my-4 md:pl-10 pl-2">
              {message}
            </div>
            <div className="text-center px-4 text-justify">
              {questionList[sequence - 1]?.question?.comment}
            </div>
          </div>
          <div
            className="quarter-circle-bottom-left"
            style={{ backgroundColor: bgcolor }}
          ></div>
          {/* <div className="flex flex-row justify-between gap-8 my-8 z-10">
          <div className="text-2xl px-8 pt-4 text-white">{message}</div>
        </div> */}
        </div>
        <div className="mb-16 sm:mt-0" ref={activityBodyEndRef}></div>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          left: 0,
          display: "flex",
          flexDirection: "column",
          zIndex: response ? 1 : -1,
          // justifyContent: "flex-end",
        }}
      // className="px-4 py-4"
      >
        <img
          alt="background_hill"
          src={background_hill}
          style={{ height: "150px", objectFit: "fit" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          className="px-4 py-4 md:mr-32 mx-auto"
        >
          <button
            onClick={handleSubmit}
            disabled={disableButton}
            className="text-white px-8 py-4 w-36 rounded-2xl text-xl shadow-lg"
            style={{
              backgroundColor: "#003366",
            }}
          >
            {response ||
              questionList[sequence - 1]?.question?.type === "flashcard"
              ? sequence === activity.length
                ? "Finish"
                : "Next"
              : "Check"}
          </button>
        </div>
      </div>
      {showQuit && (
        <NewModal
          body={
            <div>
              <div className="flex flex-row justify-center items-center">
                <h5>Are you sure to quit?</h5>
              </div>
              <div className="text-center">
                You will lose all the progress of this activity
              </div>
            </div>
          }
          footer={
            <div className="flex flex-row justify-center gap-16 items-center">
              <LinearButton
                text="Yes"
                onClick={() => {
                  history.push("/user/dashboard");
                }}
              />
              <LinearButton
                text="No"
                onClick={() => {
                  setShowQuit(false);
                }}
              />
            </div>
          }
          hasCloseButton={false}
        />
      )}
    </div>
  );
}
