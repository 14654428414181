import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ActivityList from "../../../../components/DashboardComponents/ActivityList";
import ChallengeList from "../../../../components/DashboardComponents/ChallengeList";
import DailyProgress from "../../../../components/DashboardComponents/DailyProgress";
import Leaderboard from "../../../../components/DashboardComponents/Leaderboard";
import Achievements from "../../../UserProfile/Achievment";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import AchievementIcon from "@mui/icons-material/EmojiEventsOutlined";
import NotificationIcon from "@mui/icons-material/NotificationsOutlined";
import TeamIcon from "@mui/icons-material/PeopleOutlineOutlined";
import TaskIcon from "@mui/icons-material/FormatListBulletedOutlined";

import DayNavigator from "../../../../components/DashboardComponents/DayNavigator";
import Notifications from "../../../../components/DashboardComponents/Notifications";
import GoalToggle from "../../../../components/DashboardComponents/ProgramToggle";

const MENU_OPTIONS = [
  {
    index: 0,
    text: "Home",
    value: "home",
    icon: <HomeIcon />,
  },
  {
    index: 1,
    text: "Journal",
    value: "journal",
    icon: <TaskIcon fontSize="small" />,
  },
  {
    index: 2,
    text: "Progress",
    value: "progress",
    icon: <AchievementIcon />,
  },
  {
    index: 3,
    text: "Notifications",
    value: "notifications",
    icon: <NotificationIcon />,
  },
  {
    index: 4,
    text: "Team",
    value: "team",
    icon: <TeamIcon />,
  },
];

const MENU_INDEX = ["dashboard", "progress", "journal", "team"];

export default function UserDashboardMobile() {
  const [component, setComponent] = useState("home");
  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedProgramID, setSelectedProgramID] = useState("all");

  const [date, setDate] = useState(new Date());
  const [dayAdjust, setDayAdjust] = useState(0);
  const [weekBounds, setWeekBounds] = useState({ firstday: "", lastday: "" });

  useEffect(() => {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last));

    setWeekBounds({ ...weekBounds, firstday, lastday });
  }, []);

  const history = useHistory();

  const displayComponent = () => {
    if (component === "home") {
      return (
        <div className="mb-16">
          <div className="mx-4">
            <DayNavigator
              setDayAdjust={setDayAdjust}
              setDate={setDate}
              dayAdjust={dayAdjust}
              date={date}
            />
            <GoalToggle setSelectedProgramID={setSelectedProgramID} />
          </div>
          <div className="flex flex-col items-center gap-4 mt-4">
            <h5 className="w-32 text-center task-heading task-heading-container ">
              Learn
            </h5>
            <ActivityList
              dayAdjust={dayAdjust}
              selectedProgramID={selectedProgramID}
            />
            <h5 className="w-32 text-center task-heading task-heading-container ">
              Do
            </h5>
            <ChallengeList
              dayAdjust={dayAdjust}
              selectedProgramID={selectedProgramID}
            />
          </div>
        </div>
      );
    } else if (component === "team") {
      return <Leaderboard />;
    } else if (component === "journal") {
      history.push("/journal");
      return;
      // return <JournalScreenMobile />;
    } else if (component === "progress") {
      return (
        <div className="mb-32">
          <h5 className="m-4">Daily Progress</h5>
          <DailyProgress />
          <Achievements />
        </div>
      );
    } else if (component === "notifications") {
      return <Notifications />;
    }
  };


/*
  const handleNavBarStyle = (index) => {
    if (index === selectedButton && index !== 2) {
      return {
        backgroundColor: "#E9F3FD",
        color: "#2288EE",
        borderRadius: "10px",
      };
    }
    if (index === 2) {
      return {
        height: 55,
        width: 130,
        marginLeft: "4px",
        marginRight: "4px",
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        backgroundColor: "var(--prussian-blue)",
        borderRadius: "50%",
      };
    }
  }; */

  return (
    <div className="min-h-screen -mt-2" style={{ backgroundColor: "#f9f9f9" }}>
      <div>{displayComponent()}</div>
      <div className="w-full">
        <section
          className="block fixed inset-x-0 bottom-0 z-10 shadow"
          style={{ backgroundColor: "var(--prussian-blue)", color: "white" }}
        >
          <div className="flex justify-between p-2">
            {MENU_OPTIONS.map((option, index) => {
              return (
                <div
                  key={index}
                  className="w-full justify-center inline-block text-center pt-2 pb-2"
                  onClick={() => {
                    setComponent(option.value);
                    setSelectedButton(index);
                  }}
                  // style={handleNavBarStyle(index)}
                  style={
                    index === selectedButton
                      ? {
                          backgroundColor: "#E9F3FD",
                          color: "#2288EE",
                          borderRadius: "10px",
                        }
                      : { color: "white" }
                  }
                >
                  <div className="flex flex-row justify-center items-center">
                    {option.icon}
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
}
