import React, { useState } from "react";

import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const PasswordField = ({
  label = "password",
  variant = "outlined",
  id = "outlined-basic",
  name = "password",
  _handleChange,
  style = {},
}) => {
  const [showPassword, toggleShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    toggleShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      id={id}
      label={label}
      variant={variant}
      name={name}
      onChange={_handleChange}
      inputProps={{ style: { fontSize: "25px" } }}
      InputLabelProps={{ style: { fontSize: "20px" } }}
      style={style}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
