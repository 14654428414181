import { useLocation } from "react-router";
import QuizContainer from "./Quiz";

export default function NewGetActivity(props) {
  const { activity_id } = props.match.params;
  const location = useLocation();
  const goal_id = location.state?.goal_id;
  const fetchUrl = {
    path: `strapi/get-questions`,
    params: `${goal_id}/${activity_id}`
  };

  const completionUrl = {
    path: `strapi/complete/${activity_id}`,
    body: {
      goal_id
    }
  };

  const historyUrl = {
    path: "/activity/complete",
  }

  const reviewUrl = `/activity/review/${activity_id}`;
  const submitUrl = {
    path: `strapi/response`,
  };

  return <QuizContainer
    fetchUrl={fetchUrl}
    completionUrl={completionUrl}
    historyUrl={historyUrl}
    reviewUrl={reviewUrl}
    id={activity_id}
    type="activity"
    goal_id={goal_id}
    submitUrl={submitUrl}
  />
} 
