import React from "react";
import { Switch, Route, Router, BrowserRouter } from "react-router-dom";
import AddQuestion from "./Question/Add";
import LoginAdmin from "./Login";
import { useStoreState } from "easy-peasy";
import GoalStaticSchedule from "./GoalStaticSchedule";
import GetActivity from "../Admin/GetActivity";
import AdminDashboard from "./AdminDashboard";
import AdminProgramRoutes from "./Program";
import ErrorPage from "../404RedirectPage";
import AdminTrackRoutes from "./Track";
import AdminTopicRoutes from "./Topic";
import AdminSubtopicRoutes from "./Subtopic";
import AdminGoalRoutes from "./Goal";
import AdminActivityRoutes from "./Activity";
import AdminReadingRoutes from "./Reading";
import AdminChallengeRoutes from "./Challenge";
import AdminAssessmentRoutes from "./Assessment";
import AdminList from "./AdminList";
import CreateAdmin from "./CreateAdmin";
import AdminQuestionRoutes from "./Question";
import AdminClearData from "./ClearData";
import AdminOrganizationRoutes from "./Organization";
import AdminTeamRoutes from "./Team";
import DashBoardAdmin from "./AdminDashboard/DashBoardAdmin";
import CustomerSideBar from "./SideBars/CustomerSideBar";
import CurriculumSideBar from "./SideBars/CurriculumSideBar";
import AdminHeader from "./AdminHeader/AdminHeader";
import ReportRoutes from "./Reports";

const Admin = () => {
  const admin = useStoreState((s) => s.admin.data);
  const adminRoutes = [
    { path: "/admin/clear-data", component: AdminClearData },
    { path: "/admin/organization", component: AdminOrganizationRoutes },
    { path: "/admin/team", component: AdminTeamRoutes },
    { path: "/admin/dashboard", component: AdminDashboard },
    { path: "/admin/list", component: AdminList },
    { path: "/admin/create", component: CreateAdmin },
    { path: "/admin/get-activity", component: GetActivity },
    { path: "/admin/add-question", component: AddQuestion },
    { path: "/admin/track", component: AdminTrackRoutes },
    { path: "/admin/program", component: AdminProgramRoutes },
    { path: "/admin/goal", component: AdminGoalRoutes },
    { path: "/admin/topic", component: AdminTopicRoutes },
    { path: "/admin/subtopic", component: AdminSubtopicRoutes },
    { path: "/admin/activity", component: AdminActivityRoutes },
    { path: "/admin/question", component: AdminQuestionRoutes },
    { path: "/admin/challenge", component: AdminChallengeRoutes },
    { path: "/admin/assessment", component: AdminAssessmentRoutes },
    { path: "/admin/reading", component: AdminReadingRoutes },
    { path: "/admin/goal-static-schedule", component: GoalStaticSchedule },
    // { path: "/admin/dashboarddummy", component: DashBoardAdmin },
    { path: "/admin/customer", component: CustomerSideBar },
    { path: "/admin/curriculum", component: CurriculumSideBar },
    { path: "/report", component: ReportRoutes },
  ];
  return (
    <BrowserRouter>
      {/* <AdminHeader /> */}
      <Switch>
        <Route exact path="/admin/login" component={LoginAdmin} />
        {admin &&
          adminRoutes.map((route, index) => {
            return (
              <Route
                path={`${route.path}`}
                component={route.component}
                key={index}
              />
            );
          })}
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Admin;
