import React from "react";
import { Switch, Route } from "react-router-dom";
import AdminAddAssessment from "./Add";
import AdminAssessmentList from "./List";

const AdminAssessmentRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/admin/assessment/add"
        component={AdminAddAssessment}
      />
      <Route
        exact
        path="/admin/assessment/list"
        component={AdminAssessmentList}
      />
    </Switch>
  );
};

export default AdminAssessmentRoutes;
