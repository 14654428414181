import React from "react";
import Check from "@material-ui/icons/Check";
import Cross from "@material-ui/icons/Clear";
import unlocked from "../../../assests/icons/unlock.svg";
import locked from "../../../assests/icons/locked.svg";
import useScreenSize from "../../../hooks/useScreenSize";

const ActivityRowBox = ({
    completed = false,
    missed = false,
    message = "",
    enabled = false,
    onClick = () => { },
    id = ""
} ) => {

    const { isMobile } = useScreenSize();

    const color = completed
        ? "var(--success)"
        : enabled
            ? "#2288EE"
            : missed
                ? "var(--error)"
                : "#B9B9BA";

    const statusSymbol = (!completed && !missed) ?
        <img
            src={
                enabled
                    ? unlocked
                    : locked
            }
            alt="activity-status-icon"
        /> : <></>;

    return <div className="task-option" key={id}>
        <h6
            className="task-text cursor-pointer gap-4"
            style={{
                width: isMobile ? "80vw" : "",
                color: color
            }}
            onClick={onClick}
        >
            <div>
                {statusSymbol}
            </div>
            {completed ? <Check className="-ml-4" /> : null}
            {missed ? <Cross className="-ml-4" /> : null}
            {message}
        </h6>
    </div>
};

export default ActivityRowBox;