require("dotenv").config();
const API_PATH = process.env.REACT_APP_API_ENDPOINT;
// const authToken = () => `Bearer ${localStorage.getItem("token") || undefined}`;

const SWRGet = async (path) =>
  fetch(`${API_PATH}/${path}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: authToken(),
    },
    credentials: 'include' // Ensures the cookie is sent along with the request
  }).then(async (r) => {
    return { status: r.status, data: await r.json() };
  });

const get = async (path, ...params) => {
  const url = `${API_PATH}/${path}/${params.join("/")}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: authToken(),
      },
      credentials: 'include' // Ensures the cookie is sent along with the request
    });

    const data = await response.json();
    return { status: response.status, data };
  } catch (error) {
    console.error(`Error fetching from ${url}:`, error);
    throw error;
  }
};

const post = (path, body = {}) =>
  fetch(`${API_PATH}/${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: authToken(),
    },
    credentials: 'include', // Ensures the cookie is sent along with the request
    body: JSON.stringify(body),
  }).then(async (r) => {
    return { status: r.status, data: await r.json() };
  });

const put = (path, body = {}) =>
  fetch(`${API_PATH}/${path}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      // Authorization: authToken(),
    },
    body: JSON.stringify(body),
    credentials: 'include' // Ensures the cookie is sent along with the request
  }).then((r) => r.json());

const patch = (path, body = {}) =>
  fetch(`${API_PATH}/${path}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      // Authorization: authToken(),
    },
    body: JSON.stringify(body),
    credentials: 'include' // Ensures the cookie is sent along with the request
  }).then(async (r) => {
    return { status: r.status, data: await r.json() };
  });

const remove = (path) =>
  fetch(`${API_PATH}/${path}`, {
    method: "REMOVE",
    headers: {
      "Content-Type": "application/json",
      // Authorization: authToken(),
    },
    credentials: 'include' // Ensures the cookie is sent along with the request
  }).then((r) => r.json());

export default { SWRGet, get, post, put, remove, patch };
