import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import "./index.css";
import Programs from "../..";

import profile from "../../../../assests/icons/profile/profile.svg";
import achievements from "../../../../assests/icons/profile/achievements.svg";
import team from "../../../../assests/icons/profile/team.svg";
import journal from "../../../../assests/icons/profile/journal.svg";
import admin from "../../../../assests/icons/profile/admin.svg";
import settings from "../../../../assests/icons/profile/settings.svg";

import selected_profile from "../../../../assests/icons/profile/selected_profile.svg";
import selected_achievements from "../../../../assests/icons/profile/selected_achievements.svg";
import selected_team from "../../../../assests/icons/profile/selected_team.svg";
import selected_journal from "../../../../assests/icons/profile/selected_journal.svg";
import selected_admin from "../../../../assests/icons/profile/selected_admin.svg";
import selected_settings from "../../../../assests/icons/profile/selected_settings.svg";

import MyPrograms from "../../MyPrograms";
import useSWR from "swr";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import ProfileIcon from "@mui/icons-material/PersonOutlineOutlined";
import ExploreIcon from "@mui/icons-material/ExploreOutlined";

const MENU_OPTIONS = [
  {
    index: 0,
    text: "My Programs",
    value: "my_programs",
    img: profile,
    selected_img: selected_profile,
    icon: <ProfileIcon />,
  },
  {
    index: 2,
    text: "Home",
    value: "home",
    icon: <HomeIcon />,
  },
  {
    index: 2,
    text: "Explore",
    value: "explore",
    img: journal,
    selected_img: selected_journal,
    icon: <ExploreIcon />,
  },
];

const MENU_INDEX = ["explore", "my_programs"];

export default function ProgramScreenMobile(props) {
  const [component, setComponent] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const { data: userProgramList } = useSWR("program/get-enrolled");
  const history = useHistory();

  useEffect(() => {
    if (userProgramList?.length >= 1) {
      setComponent("my_programs");
      setSelectedButton(0);
    } else if (userProgramList?.length === undefined) {
      setComponent(null);
      setSelectedButton(null);
    } else {
      setComponent("explore");
      setSelectedButton(1);
    }
  }, [userProgramList]);


  const displayComponent = () => {
    if (component === "explore") {
      return <Programs />;
    } else if (component === "my_programs") {
      if (userProgramList?.length === 0) {
        return (
          <div className="m-4">
            The programs you are enrolled in appears here.
          </div>
        );
      }
      return <MyPrograms />;
    } else if (component === "home") {
      history.push("/user/dashboard");
      return null;
    }
  };

  const handleNavBarStyle = (index) => {
    if (index === selectedButton && index !== 1) {
      return {
        backgroundColor: "#E9F3FD",
        color: "#2288EE",
        borderRadius: "10px",
      };
    }
    if (index === 1) {
      return {
        height: 55,
        width: 100,
        marginLeft: "4px",
        marginRight: "4px",
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        backgroundColor: "var(--prussian-blue)",
        borderRadius: "50%",
      };
    }
  };

  return (
    <div style={{ backgroundColor: "#F9F9F9" }} className="w-screen">
      <div>{displayComponent()}</div>
      <div className="w-full mb-24">
        <section className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
          <div className="flex justify-between p-4">
            {MENU_OPTIONS.map((option, index) => {
              return (
                <div
                  key={index}
                  className="w-full justify-center inline-block text-center pt-2 pb-1"
                  onClick={() => {
                    setComponent(option.value);
                    setSelectedButton(index);
                  }}
                  style={handleNavBarStyle(index)}
                  // style={
                  //   index === selectedButton
                  //     ? {
                  //         backgroundColor: "#E9F3FD",
                  //         color: "#2288EE",
                  //         borderRadius: "10px",
                  //       }
                  //     : null
                  // }
                >
                  <div className="flex flex-col items-center">
                    {/* <img
                      src={
                        selectedButton === index && index !== 1
                          ? option.selected_img
                          : option.img
                      }
                    /> */}
                    {/* {index === 1 && <HomeIcon />} */}
                    {option.icon}
                    <span className="tab tab-explore block text-xs">
                      {option.text}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
}
