import React, { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { API } from "../../../api";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import LinearTextBox from "../../../components/TextBox";
import LinearButton from "../../../components/Button";

const AdminDashboard = () => {
  const history = useHistory();
  const [activityList, setActivityList] = useState();
  const [programList, setProgramList] = useState();
  const [goalList, setGoalList] = useState();
  const [subtopicList, setSubtopicList] = useState();
  const [filter, setFilter] = useState({
    program_id: "",
    goal_id: "",
    subtopic_id: "",
  });

  useEffect(() => {
    async function getProgramList() {
      const { data } = await API.get("program", "all");
      setProgramList([" ", ...data]);
    }
    async function getGoalList() {
      const { data } = await API.get("goal", "all");
      setGoalList([" ", ...data]);
    }
    async function getSubtopicList() {
      const { data } = await API.get("subtopic", "all");
      setSubtopicList([" ", ...data]);
    }
    async function getActivityList() {
      const { data } = await API.get("strapi", "all");
      setActivityList(data);
    }
    // getProgramList();
    // getGoalList();
    // getSubtopicList();
    getActivityList();
  }, []);

  const _handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  // const _handleFilter = async () => {
  //   const { data } = await API.get(
  //     "strapi/all",
  //     `?program_id=${filter.program_id}&goal_id=${filter.goal_id}&subtopic_id=${filter.subtopic_id}`
  //   );
  //   setActivityList(data);
  // };

  // const [inProgress, toggleProgress] = useState(false);
  // const [alertVisible, toggleAlertVisibility] = useState(false);
  // const [alertSeverity, setAlertSeverity] = useState("");
  // const [alertMessage, setAlertMessage] = useState("");

  // const _goToPage = (pageName) => {
  //   history.push(`/admin/${pageName}/add`);
  // };

  // const _goToListPage = (pageName) => {
  //   history.push(`/admin/${pageName}/list`);
  // };
  //   const _handleSubmit = async () => {
  //     try {
  //       toggleProgress(true);
  //       const { data, status } = await API.post("track/add", trackData);
  //       if (status === 200) {
  //         setAlertMessage("Track has been added");
  //         setAlertSeverity("info");
  //         toggleAlertVisibility(true);
  //         toggleProgress(false);
  //       } else {
  //         setAlertMessage(data.error ? data.error.message : "Error Occured");
  //         setAlertSeverity(data.error ? "error" : "info");
  //         toggleAlertVisibility(true);
  //         toggleProgress(false);
  //       }
  //     } catch (err) {
  //       setAlertMessage("An error occured. Please try again later.");
  //       setAlertSeverity("error");
  //       toggleAlertVisibility(true);
  //       toggleProgress(false);
  //     }
  //   };

  return (
    <div className="h-full flex justify-center items-center">
      <div className="shadow-xl p-36">
        <h3>List of activities</h3>
        {/* <select className="p-4 mb-4" onChange={_handleChange} name="program_id">
          {programList?.map((program, index) => {
            return (
              <option key={index} value={program.id}>
                {program.name}
              </option>
            );
          })}
        </select>
        <select className="p-4 mb-4" onChange={_handleChange} name="goal_id">
          {goalList?.map((goal, index) => {
            return (
              <option key={index} value={goal.id}>
                {goal.name}
              </option>
            );
          })}
        </select>
        <select
          className="p-4 mb-4"
          onChange={_handleChange}
          name="subtopic_id"
        >
          {subtopicList?.map((subtopic, index) => {
            return (
              <option key={index} value={subtopic.id}>
                {subtopic.name}
              </option>
            );
          })}
        </select> */}
        {activityList?.map((activity, index) => {
          return (
            <div key={activity.id}
              className="cursor-pointer p-4 border-2 rounded-xl my-4"
              onClick={() => {
                history.push(`/admin/activity/preview/${activity.id}`);
              }}
            >
              {activity.name}
            </div>
          );
        })}
        {/* <LinearButton text="Go" onClick={_handleFilter} /> */}
        {/* <div className="text-center text-3xl">Admin Dashboard</div>
        <div className=" flex flex-row gap-4 justify-center">
          <div
            style={{
              marginTop: "10%",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToPage("track")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "Add Track"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToPage("program")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "Add Program"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToPage("goal")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "Add Goal"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToPage("topic")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "Add Topic"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToPage("subtopic")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "Add Subtopic"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToPage("activity")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "Add Activity"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToPage("challenge")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "Add Challenge"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToPage("reading")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "Add Reading"}
            </Button>
          </div>
          <div
            style={{
              marginTop: "10%",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToListPage("track")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "List Track"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToListPage("program")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "List Program"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToListPage("goal")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "List Goal"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToListPage("topic")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "List Topic"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToListPage("subtopic")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "List Subtopic"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/admin/activity/all")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "List Activity"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/admin/challenge/all")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "List Challenge"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => _goToListPage("reading")}
              style={{ padding: "20px" }}
              disabled={inProgress}
            >
              {inProgress ? <CircularProgress /> : "List Reading"}
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AdminDashboard;
