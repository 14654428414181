import React from "react";
import AddIcon from "@mui/icons-material/Add";

export default function ExpandedSelector({ text, action }) {
  return (
    <div
      className="rounded-full bg-blue-300 text-blue-700 w-10 hover:w-56 flex flex-row items-center p-2 gap-2"
      style={{
        transition: "all 250ms linear",
        transitionDelay: "100ms",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      <AddIcon className="rounded-full hover:bg-blue-200" />
      <span onClick={action} className="cursor-pointer">
        {text}
      </span>
    </div>
  );
}
