import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "../../../../../api";
import { CircularProgress } from "@material-ui/core";
import Table from "../../../../../components/Table";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const UserProgram = ({ email }) => {
  const history = useHistory();

  const [programsEnrolled, setProgramsEnrolled] = useState([]);

  const [programTableHeader, setProgramTableHeader] = useState([]);
  const [programTableBody, setProgramTableBody] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const getProgramEnrolled = async (userEmail) => {
    try {
      const { data } = await API.get("user/program-enrolled", userEmail);
      setProgramsEnrolled(data);
    } catch (error) {
      console.log(error?.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProgramEnrolled(email);
  }, []);

  useEffect(() => {
    const tableData = [];
    programsEnrolled &&
      programsEnrolled?.map((program, index) => {
        const programName = program?.goal?.program?.name;
        const startDate = program?.start_date;
        const enrolledOn = startDate?.split("T")[0];
        const goal = program?.goal?.name;
        const completionPercentage = program?.completion_percentage + "" + "%";
        const status =
          parseInt(completionPercentage) === 0 ? "Yet To Start" : "Ongoing";
        const element = [
          programName,
          goal,
          enrolledOn,
          status,
          completionPercentage,
        ];
        tableData.push(element);
      });
    setProgramTableHeader([
      "Program Name",
      "Goal",
      "Enrolled On",
      "Status",
      "Completion %",
    ]);
    setProgramTableBody(tableData);
  }, [programsEnrolled]);

  return (
    <div>
      <nav>
        <div className="text-center my-4 text-gray-500 mt-0">
          <button
            onClick={() => {
              history.push("/administrator", {
                component: "user",
              });
            }}
            className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
          >
            <svg
              className="w-6 h-6"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <ArrowBackIcon />
            </svg>
            <p className="text-gray-800">Back</p>
          </button>
        </div>
      </nav>

      <main>
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <div style={{ marginTop: "-20vh" }}>
              <CircularProgress size={70} />
            </div>
          </div>
        ) : programsEnrolled.length > 0 ? (
          <div className="mt-2">
            <Table header={programTableHeader} body={programTableBody} />
          </div>
        ) : (
          <div class="flex justify-center items-start bg-white ">
            <div class="text-center p-8 bg-white-200 rounded-t-lg shadow-md w-full md:w-1/2">
              <p class="text-3xl font-bold text-gray-500 mb-4">
                No Enrolled Programs
              </p>
              <p class="text-gray-700">
                It looks like user has not enrolled into any program
              </p>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default UserProgram;
