import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../404RedirectPage";
import GroupLeaderboard from "./GroupLeaderboard";
import GroupJoinLinkPage from "./JoinLink";

const GroupRouter = () => {
  return (
    <Switch>
      <Route exact path="/group/leaderboard" component={GroupLeaderboard} />
      <Route
        exact
        path="/group/join-link/:email/:group_id"
        component={GroupJoinLinkPage}
      />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default GroupRouter;
