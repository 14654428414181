import React, { useEffect, useState } from "react";
import "./index.css";
import DragList from "react-list-drag-and-drop/lib/RLDD";

const Order = (props) => {
  const { id } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    }
    shuffleArray(props.options);
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    handleSubmitResponse();
  }, [options]);

  const handleDragListChange = (newOrder) => {
    setOptions(newOrder);
  };

  const handleSubmitResponse = async () => {
    const order = {};
    let counter = 1;
    for (const option of options) {
      order[counter] = option.mcq_option.id;
      counter++;
    }
    const orderResponse = {
      id,
      type: "order",
      answer: order,
    };
    props.sendResponse(orderResponse);
  };

  if (!options) return null;

  return (
    <div>
      <div>
        <DragList
          items={options}
          itemRenderer={(option) => {
            return (
              <div className="option body-medium-1 p-6 my-4">
                {option?.mcq_option?.option_text}
              </div>
            );
          }}
          onChange={handleDragListChange}
        />
      </div>
    </div>
  );
};

export default Order;
