import React, { useState, useEffect } from "react";
import { API } from "../../../../api";
import { useHistory } from "react-router";

const AdminTrackList = () => {
  const [trackList, setTrackList] = useState();
  const history = useHistory();

  useEffect(() => {
    async function getTrackList() {
      const { data } = await API.get("track", "all");
      setTrackList(data);
    }
    getTrackList();
  }, []);

  return (
    <div>
      {trackList?.map((track, index) => {
        return (
          <div key={index} className="p-4 shadow-xl m-4 ">
            <div className="text-2xl">{track.name}</div>
            <div>
              <span className="text-xl text-blue-700">Description:</span>{" "}
              {track.description}
            </div>
            <div>
              <div>
                <span className="text-xl text-blue-700">
                  Program Under this track:
                </span>{" "}
              </div>
              {track.programs?.map((program, index) => {
                return <div key={index}>{program.name}</div>;
              })}
            </div>
            <div>
              <span className="text-xl text-blue-700">Status:</span>{" "}
              {track.active ? "Active" : "Inactive"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AdminTrackList;
