import { ReactComponent as CloseButton } from "../../assests/icons/ic/close.svg";
import useScreenSize from "../../hooks/useScreenSize";
import "./index.css";
const NewModal = ({
  heading = "",
  hasCloseButton = true,
  footer = <div></div>,
  body = <div></div>,
  ModalStyle = {},
  onClose = () => {},
}) => {
  const { isMobile } = useScreenSize();
  return (
    <div className="modal-style">
      <div
        className={isMobile ? "modal-content-mobile" : "modal-content"}
        style={ModalStyle}
      >
        <div className="modal-header">
          <div></div>
          <h5>{heading}</h5>
          {hasCloseButton ? (
            <CloseButton
              onClick={onClose}
              title="close"
              className="cursor-pointer"
            />
          ) : (
            <div></div>
          )}
        </div>
        <div className="modal-body">{body}</div>
        <div className="modal-footer">{footer}</div>
      </div>
    </div>
  );
};

export default NewModal;
