import error_img from "../../assests/icons/error_page.svg";
import "./index.css";

const ErrorPage = ({ location }) => {
  return (
    <>
      <div className="flex flex-col md:flex-row h-screen gap-16 mt-10 md:mt-0 items-center md:justify-center">
        <div style={{ width: "75%" }}>
          <img alt="error_img" src={error_img} />
        </div>
        <div style={{ width: "75%" }}>
          <div>
            <h1 className="title highlight mb-8">404</h1>
            <h3 className="subtitle">
              This URL <code className="highlight">{location.pathname}</code> is
              not found. You could go back to{" "}
              <a href="/" className="highlight">
                Home
              </a>{" "}
              or press back button.
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
