import React, { useState, useEffect } from "react";
import { API } from "../../../../api";

const AdminTopicList = () => {
  const [topicList, setTopicList] = useState();

  useEffect(() => {
    async function getTopicList() {
      const { data } = await API.get("topic", "all");
      setTopicList(data);
    }
    getTopicList();
  }, []);

  return (
    <div>
      {topicList?.map((topic, index) => {
        return (
          <div key={index} className="p-4 shadow-xl m-4 ">
            <div className="text-2xl">{topic.name}</div>
            <div>
              <span className="text-xl text-blue-700">Description:</span>{" "}
              {topic.description}
            </div>
            <div>
              <div>
                <span className="text-xl text-blue-700">
                  Topics Under this goal:
                </span>{" "}
              </div>
              {topic.topic_subtopic_maps?.map((subtopic, index) => {
                return <div key={index}>{subtopic.subtopic?.name}</div>;
              })}
            </div>
            <div>
              <span className="text-xl text-blue-700">Status:</span>{" "}
              {topic.active ? "Active" : "Inactive"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AdminTopicList;
