import React from "react";
import { Switch, Route } from "react-router-dom";
//import AssessmentQuestions from "./AssessmentQuestions";
import AssessmentResult from "./AssessmentResults";
import AssessmentQuestions from "./AssessmentQuestions";
// import AssessmentQuestions from "./Strapi-assessment";

const AssessmentRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/assessment/questions/:program_id"
        component={AssessmentQuestions}
      />
      <Route
        exact
        path="/assessment/result/:program_id"
        component={AssessmentResult}
      />
    </Switch>
  );
};

export default AssessmentRoutes;
