import React, { useEffect, useState } from "react";
import { API } from "../../../api";
import { useHistory } from "react-router-dom";
import LinearButton from "../../../components/Button";

const GroupJoinLinkPage = (props) => {
  const [groupID, setGroupID] = useState();
  const [message, setMessage] = useState("");
  const history = useHistory();

  const email = props.match.params.email;

  useEffect(() => {
    setGroupID(props.match.params.group_id);
    async function joinGroup() {
      const { data, status } = await API.post(`group/accept-invite/${groupID}`);
      if (status === 200) {
        setMessage("You have successfully joined the group");
      } else if (status === 401) {
        const userRegistered = await API.get(
          "user",
          `check-registered/${email}`
        );
        let path = "signup";
        if (userRegistered?.data?.registered) path = "login";
        history.push(`/user/${path}`, {
          return_url: `/group/join-link/${email}/${groupID}`,
          email,
        });
      } else {
        setMessage(data.error.message);
      }
    }
    if (groupID) joinGroup();
  });
  return (
    <div className="flex flex-col gap-4 justify-center items-center h-screen">
      <div>{message}</div>
      <div className="flex gap-4">
        <LinearButton
          size="extra-large"
          onClick={() => {
            history.push("/user/dashboard");
          }}
          text="Go to Dashboard"
        />
        {/* <LinearButton
          onClick={() => {
            history.push("/user/login", {
              return_url: `/group/join-link/${email}/${groupID}`,
            });
          }}
          text="Login"
        /> */}
      </div>
    </div>
  );
};

export default GroupJoinLinkPage;
