import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import background_hill from "../../../../../assests/icons/background_hill.png";
import CancelIcon from "@material-ui/icons/Clear";
import { API } from "../../../../../api";
import "../index.css";

// Components
import MCQ from "../../MCQ";
import FillBlanks from "../../FillBlanks";
import ProgressBar from "../../../../../components/Activity/ProgressBar";
import Order from "../../Order";
import NewModal from "../../../../../components/NewModal";
import LinearButton from "../../../../../components/Button";
import useScreenSize from "../../../../../hooks/useScreenSize";
import useActivityState from "../../activityData";
import { completeActivity } from "../../activityFunctions";
import Comment from "../../Comment";
import ExpandedSelector from "../../../../../components/ExpandedSelector";
import { CircularProgress } from "@material-ui/core";
import FlashCard from "../../../../ChallengeNew/FlashCard";

const QuizContainer = ({
	historyUrl,
	fetchUrl,
	completionUrl,
	reviewUrl = "",
	submitUrl,
	id,
	type,
	goal_id
}) => {


	const [activity, setActivity] = useState();
	const [questionList, setQuestionList] = useState([]);
	const [question, setQuestion] = useState();
	const [currentSequence, setCurrentSequence] = useState(0);
	const [sequence, setSequence] = useState(0);
	const [maxSequence, setMaxSequence] = useState(1);
	const [questionResponse, setQuestionResponse] = useState();
	const [bgcolor, setBgColor] = useState("#FFFFFF");
	const [message, setMessage] = useState("");
	const [snippetShow, setSnippetShow] = useState(false);
	const [response, setResponse] = useState();
	const [isLoading, toggleLoading] = useState(true);
	const [buttonLoading, toggleButtonLoading] = useState(false);
	const [showQuit, setShowQuit] = useState(false);
	const [lastResponded, setLastResponded] = useState();
	const [noteSaveText, setNoteSaveText] = useState("");
	const [questionAddedToNotes, setQuestionAddedToNotes] = useState([]);
	const activityBodyEndRef = useRef();
	const activityBodyStartRef = useRef();
	const location = useLocation();
	const history = useHistory();
	const { activityState, addNewResponse } = useActivityState();
	const [answer, setAnswer] = useState();
	const [showFlashCardSnippet, toggleflashCardSnippet] = useState(false);
	const [flashCardSaveText, setFlashCardNoteText] = useState("")

	const { isMobile } = useScreenSize();
	const activityStartTime = new Date().getTime();

	const activityName = location.state?.activity_name;

	useEffect(() => {
		if (message)
			activityBodyEndRef.current?.scrollIntoView({ behavior: "smooth" });
		else {
			activityBodyStartRef.current?.scrollIntoView({ behaviour: "smooth" });
		}
	}, [message]);

	const getResponse = (response) => {
		setQuestionResponse(response);
	};

	const addToNote = async () => {
		const note_title = `${activityName} - Question ${sequence}`;
		const note_text = `Question - ${question.stem} 

			Correct Answer - ${response?.correctAnswer?.option_text}
 
			${question.comment}
		`;
		if (!questionAddedToNotes.includes(currentSequence)) {
			const { status } = await API.post("user-data/add-note", {
				note_title,
				note_text,
			});
			if (status === 200) {
				setNoteSaveText("Added to Notes");
				setQuestionAddedToNotes([...questionAddedToNotes, sequence]);
			}
		}
	};

	const addToFlashCards = async () => {
		const flashcard_title = `${activityName} - Flashcard ${sequence}`;
		const flashcard_text = `${question.stem}`;
		try {
			if (!questionAddedToNotes.includes(currentSequence)) {
				const { status } = await API.post("user-data/flashcard", {
					flashcard_title,
					flashcard_text,
				});
				if (status === 200) {
					setFlashCardNoteText("Added to FlashCards");
					setQuestionAddedToNotes([...questionAddedToNotes, sequence]);
				}
			}
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		async function fetchData() {
			const { path, params } = fetchUrl;
			const { data } = await API.get(path, params);
			setQuestionList(data);
			setActivity(data);
			setMaxSequence(data.length);
			toggleLoading(false);
			setSequence(1);
			setCurrentSequence(1);
			// If reattempt is not enabled, go to review mode
			if ((data.hasOwnProperty("reattempt") && data.reattempt === false)) {
				history.replace(reviewUrl, {
					goal_id: goal_id,
				});
			}
		}
		fetchData();
	}, []);

	const _nextQuestion = async (id) => {
		if (sequence < maxSequence && currentSequence === sequence)
			setSequence(sequence + 1);

		setCurrentSequence((currentSequence) => currentSequence + 1);
	};

	const submitActivity = () => {
		completeActivity({ history, activityStartTime, toggleButtonLoading, historyUrl, completionUrl });
	}

	const moveForward = (e) => {
		if (e.target.textContent.trim() === "Next") {
			setBgColor("#FFFFFF");
			_nextQuestion();
			if (currentSequence === sequence) {
				setMessage();
				setResponse();
				setSnippetShow(false);
				toggleflashCardSnippet(false);
			}
		} else if (e.target.textContent.trim() === "Finish") {
			setMaxSequence(maxSequence - 1);
			submitActivity();
			e.target.textContent = "Finish";
		}
	};

	const handleSubmit = async (e) => {
		const idKey = type === "activity" ? "activity_id" : "revision_id";
		if (questionList[sequence - 1]?.question?.type !== "flashcard") {
			// Flashcards don't have any response
			questionResponse[idKey] = id;
			questionResponse["goal_id"] = goal_id;
			questionResponse["question_id"] = questionList[sequence - 1].question_id;
		}

		toggleLoading(true);
		if (e.target.textContent.trim() === "Check") {
			toggleButtonLoading(true);
			const { data, status } = await API.post(
				submitUrl.path,
				questionResponse
			);
			setResponse(data);
			setSnippetShow(true);
			if (data.isUserCorrect) {
				setBgColor("#0ACC81");
				setMessage("CORRECT ANSWER");
			} else {
				setBgColor("#CC3414");
				setMessage("INCORRECT ANSWER");
			}
			addNewResponse({
				id: questionList[sequence - 1].question_id,
				correctAnswer: data?.correctAnswer,
				isUserCorrect: data?.isUserCorrect,
				comment: questionList[sequence - 1]?.question?.comment,
				userResponse: questionResponse.answer,
			});
			setLastResponded(questionList[sequence - 1]?.question_id);
			setAnswer();
			toggleButtonLoading(false);
		} else if (e.target.textContent.trim() !== "Finish") {
			if (questionList[sequence - 1]?.question?.type === "flashcard") {
				addNewResponse({
					id: questionList[sequence - 1].question_id,
					correctAnswer: questionList[sequence - 1].stem,
					isUserCorrect: true,
					comment: "",
					userResponse: { answer: "", type: "flashcard" },
				});
				setLastResponded(questionList[sequence - 1]?.question_id);
				setAnswer();
			}
			moveForward(e);
		} else {
			moveForward(e);
		}
	};

	const displayQuestion = (type) => {
		if (type === "mcq")
			return (
				<MCQ
					buttonLoading={buttonLoading}
					setAnswer={setAnswer}
					answer={answer}
					options={question.options}
					id={question.id}
					sendResponse={getResponse}
					isUserCorrect={
						response && sequence === currentSequence
							? response?.isUserCorrect
							: activityState[currentSequence - 1]?.isUserCorrect
					}
					correctAnswer={
						response && sequence === currentSequence
							? response?.correctAnswer
							: activityState[currentSequence - 1]?.correctAnswer
					}
					selected={activityState[currentSequence - 1]?.userResponse}
				/>
			);
		else if (type === "fill_blanks") {
			return (
				<FillBlanks
					setAnswer={setAnswer}
					answer={answer}
					sendResponse={getResponse}
					id={questionList[sequence - 1].question_id}
					selected={activityState[currentSequence - 1]?.userResponse}
				/>
			);
		} else if (type === "order") {
			return (
				<Order
					setAnswer={setAnswer}
					answer={answer}
					id={question.id}
					options={question.options}
					sendResponse={getResponse}
				/>
			);
		} else if (type === "flashcard") {
			return (
				<>
					<FlashCard
						image={questionList[sequence - 1].question?.question_image_url}
						id={questionList[sequence - 1].question?.id}
					/>
				</>
			);
		}
	};

	useEffect(() => {
		toggleflashCardSnippet(false);
		setQuestion({
			id: questionList[currentSequence - 1]?.question_id,
			stem: questionList[currentSequence - 1]?.question?.stem,
			type: questionList[currentSequence - 1]?.question?.type,
			options: questionList[currentSequence - 1]?.question?.components,
			comment: questionList[currentSequence - 1]?.question?.comment,
		});
		if (questionAddedToNotes.includes(currentSequence)) {
			setNoteSaveText("Added to Notes");
			if (questionList[currentSequence - 1]?.question?.type === "flashcard") {
				toggleflashCardSnippet(true);
				setFlashCardNoteText("Added To FlashCards");
			}
		} else {
			setNoteSaveText("Add to notes")
			if (questionList[currentSequence - 1]?.question?.type === "flashcard") {
				toggleflashCardSnippet(true);
				setFlashCardNoteText("Add To FlashCards");
			}
		};

	}, [currentSequence]);

	if (!activity || !questionList) return null;
	if (!goal_id) return null;

	return (
		<div className="w-11/12 sm:w-2/3 flex flex-col gap-4 mx-auto">
			<div className="flex flex-row justify-center items-center">
				<ProgressBar
					bgcolor={"#ef6c00"}
					completed={parseInt(((sequence - 1) / maxSequence) * 100)}
				/>
				<CancelIcon
					className="cursor-pointer"
					onClick={() => {
						setShowQuit(true);
					}}
				/>
			</div>
			<div
				className="scroll"
				style={{
					zIndex: 100,
					overflowY: "scroll",
					scrollBehavior: "smooth",
					maxHeight: "65vh",
				}}
			>
				<div ref={activityBodyStartRef}></div>
				<div style={{ whiteSpace: "pre-wrap" }}>
					{isMobile || question.stem?.split(" ").length > 20 ? (
						<h6 className="mb-4 text-justify">{question.stem}</h6>
					) : (
						<h5 className="text-center ">{question.stem}</h5>
					)}

					<div style={{ marginBottom: "15px" }}>
						{displayQuestion(question.type)}
					</div>
				</div>

				{question.type === "flashcard" && <div> {question.comment}</div>}
				<div className="relative">
					{question.type !== "flashcard" && <Comment
						message={
							activityState[currentSequence - 1]?.isUserCorrect !== undefined
								? activityState[currentSequence - 1]?.isUserCorrect
									? "CORRECT ANSWER"
									: "INCORRECT ANSWER"
								: message
						}
						comment={[question.comment]}
						bgcolor={
							activityState[currentSequence - 1]?.isUserCorrect !== undefined
								? activityState[currentSequence - 1]?.isUserCorrect
									? "#0ACC81"
									: "#CC3414"
								: message
						}
						snippetShow={currentSequence < sequence ? true : snippetShow}
					/>}
					<div
						className="absolute z-20"
						style={{
							left: isMobile ? "40%" : "45%",
							marginTop: "-20px",
							display:
								currentSequence < sequence || snippetShow ? "block" : "none",
						}}
					>
						<ExpandedSelector text={noteSaveText} action={addToNote} />
					</div>

					<div
						className="absolute z-20"
						style={{
							left: isMobile ? "40%" : "45%",
							marginTop: "-20px",
							display: showFlashCardSnippet ? "block" : "none",
						}}
					>
						<ExpandedSelector text={flashCardSaveText} action={addToFlashCards} />
					</div>

				</div>
				<div className="mb-16 sm:mt-0" ref={activityBodyEndRef}></div>
			</div>
			<div
				style={{
					position: "fixed",
					bottom: 0,
					width: "100%",
					left: 0,
					display: "flex",
					flexDirection: "column",
					zIndex: response ? 1 : -1,
					// justifyContent: "flex-end",
				}}
			// className="px-4 py-4"
			>
				<img
					alt="background_hill" src={background_hill}
					style={{ height: "150px", objectFit: "fit" }}
				/>
				<div className="p-4 flex flex-row justify-between mx-2 md:mx-32">
					{currentSequence > 1 ? (
						<button
							onClick={() => {
								setCurrentSequence((currentSequence) => currentSequence - 1);
							}}
							className="text-white px-8 py-4 w-36 rounded-2xl text-xl shadow-lg"
							style={{
								backgroundColor: buttonLoading ? "grey" : "#003366",
							}}
							disabled={buttonLoading}
						>
							Back
						</button>
					) : (
						<div></div>
					)}
					<button
						onClick={handleSubmit}
						disabled={!(!buttonLoading && (sequence === activity.length || question?.type === "flashcard" ? true : (activityState[currentSequence - 1] ? activityState[currentSequence - 1].userResponse : (answer || response))))}
						className="text-white px-8 py-4 w-36 rounded-2xl text-xl shadow-lg"
						style={{
							backgroundColor: (sequence === activity.length || question?.type === "flashcard" ? true : activityState[currentSequence - 1] ? activityState[currentSequence - 1].userResponse : (answer || response)) ? !buttonLoading ? "#003366" : "grey" : "grey",
						}}
					>
						{buttonLoading ? <CircularProgress size="1.25rem" /> : question.id === lastResponded ||
							response ||
							questionList[sequence - 1]?.question?.type === "flashcard" ||
							currentSequence < sequence
							? sequence === activity.length
								? "Finish"
								: "Next"
							: "Check"}
					</button>
				</div>
			</div>
			{showQuit && (
				<NewModal
					body={
						<div>
							<div className="flex flex-row justify-center items-center">
								<h5>Are you sure to quit?</h5>
							</div>
							<div className="text-center">
								You will lose all the progress of this activity
							</div>
						</div>
					}
					footer={
						<div className="flex flex-row justify-center gap-16 items-center">
							<LinearButton
								text="Yes"
								onClick={() => {
									history.push("/user/dashboard");
								}}
							/>
							<LinearButton
								text="No"
								onClick={() => {
									setShowQuit(false);
								}}
							/>
						</div>
					}
					hasCloseButton={false}
				/>
			)}
		</div>
	);
};

export default QuizContainer;
