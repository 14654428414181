import React, { useState, useEffect } from "react";
import { API } from "../../../../api";

const AdminSubtopicList = () => {
  const [subtopicList, setSubtopicList] = useState();

  useEffect(() => {
    async function getSubtopicList() {
      const { data } = await API.get("subtopic", "all");
      setSubtopicList(data);
    }
    getSubtopicList();
  }, []);

  return (
    <div>
      {subtopicList?.map((subtopic, index) => {
        return (
          <div key={index} className="p-4 shadow-xl m-4 ">
            <div className="text-2xl">{subtopic.name}</div>
            <div>
              <span className="text-xl text-blue-700">Description:</span>{" "}
              {subtopic.description}
            </div>
            <div>
              <span className="text-xl text-blue-700">Status:</span>{" "}
              {subtopic.active ? "Active" : "Inactive"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AdminSubtopicList;
