import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "../../../api";
import clap from "../../../assests/icons/clap.svg";
import LinearButton from "../../../components/Button";
import useScreenSize from "../../../hooks/useScreenSize";
import "./index.css";
import Confetti from "react-confetti";

const DIFFICULTY_LEVELS = [
  { key: 0, text: "Very Easy", value: 0 },
  { key: 1, text: "Easy", value: 1 },
  { key: 2, text: "Neither Easy nor Difficult", value: 2 },
  { key: 3, text: "Difficult", value: 3 },
  { key: 4, text: "Very Difficult", value: 4 },
];

const ActivityComplete = (props) => {
  const [activityFeedback, setActivityFeedback] = useState(null);
  const [timeElapsed, setTimeElapsed] = useState({ minutes: 0, seconds: 0 });
  const reading = props.location?.state?.reading;
  const userActivityID = props.location?.state?.data?.user_activity_id;
  const history = useHistory();
  const location = useLocation();
  const activityStartTime = props.location?.state?.activityStartTime;
  const activityEndTime = props.location?.state?.activityEndTime;
  const { isMobile } = useScreenSize();
  const isRevision = location.pathname.split("/").includes("revision");

  const _handleClick = async (id) => {
    const { status } = await API.post(`user-data/save-reading/${id}`);
    if (status === 200) {
    }
  };

  useEffect(() => {
    const seconds = Math.ceil(
      ((activityEndTime - activityStartTime) / 1000) % 60
    );
    const minutes = Math.ceil(
      (activityEndTime - activityStartTime) / (1000 * 60)
    );
    setTimeElapsed({ seconds, minutes });
  }, [activityStartTime]);

  if (reading) {
    return (
      <div
        className="flex flex-row justify-center items-center h-screen gap-8 mx-16 flex-wrap lg:flex-nowrap"
        style={{ backgroundColor: "F9F9F9" }}
      >
        <div className="flex flex-col items-center lg:w-1/2 ">
          <img src={clap} />
          <h1 style={{ color: "#09B746" }}>CONGRATULATIONS!</h1>
          <div className="subtitle text-center">
            You have successfully completed the activity
          </div>
          <div className="body-large my-4 text-center">
            You have earned {props.location?.state?.data?.user_score} points in
            this activity
          </div>
          <button
            className="button-large text-white p-4 px-8"
            style={{ background: "#003366", borderRadius: "10px" }}
            onClick={() => history.push("/user/dashboard")}
          >
            Exit
          </button>
        </div>
        <div className="container p-4 lg:w-1/2 mb-16">
          <p className="mb-16 body-medium-1">{reading.reading_text}</p>
          <LinearButton
            type="secondary"
            text="Save"
            onClick={() => _handleClick(reading.id)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center h-screen mx-4 text-center items-center">
      <img src={clap} />
      {isMobile ? (
        <h3 style={{ color: "#09B746" }}>CONGRATULATIONS!</h3>
      ) : (
        <h1 style={{ color: "#09B746" }}>CONGRATULATIONS!</h1>
      )}
      <div className="text-xl font-bold sm:subtitle">
        You have successfully completed the activity
      </div>
      <div className="body-medium-1 my-4">
        You earned {props.location?.state?.data?.user_score} XP points in this
        activity
      </div>
      {/* <div className="body-large my-4">
        You completed this activity in{" "}
        <span className="font-semibold">
          {timeElapsed.minutes ? timeElapsed.minutes : 0} minute(s){" "}
          {timeElapsed.seconds ? timeElapsed.seconds : 0} seconds{" "}
        </span>
        and earned{" "}
        <span className="font-semibold">
          {props.location?.state?.data?.user_score} XP points
        </span>
        .
      </div> */}
      {!isRevision ? (
        <div>
          <h5>How did you find this activity?</h5>
          {DIFFICULTY_LEVELS.map((difficulty, index) => {
            return (
              <div
                key={index}
                className="body-small-1 py-1.5 px-4 flex flex-row items-center gap-1.5"
                style={{
                  border: "1px solid #aaa",
                  borderRadius: 10,
                  margin: 10,
                }}
              >
                <input
                  type="radio"
                  name="difficulty_level"
                  value={difficulty.value}
                  onChange={() => {
                    setActivityFeedback(difficulty.value);
                  }}
                  id={difficulty.key}
                  className="cursor-pointer"
                  style={{ height: "20px", width: "20px" }}
                />
                <label htmlFor={difficulty.key} className="cursor-pointer">
                  {"  "}
                  {difficulty.text}
                </label>
              </div>
            );
          })}
        </div>
      ) : null}
      <button
        className="button-large text-white p-4 px-8 mt-4"
        style={{ background: "#003366", borderRadius: "10px" }}
        onClick={async () => {
          if (activityFeedback !== null)
            await API.post("strapi/user-feedback", {
              activity_feedback: activityFeedback,
              user_activity_id: userActivityID,
            });
          history.push("/user/dashboard");
        }}
      >
        Exit
      </button>
      <Confetti
        width={window.width}
        height={window.height}
        numberOfPieces={200}
      />
    </div>
  );
};

export default ActivityComplete;
