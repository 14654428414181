import React from "react";

const SubscriptionDetails = () => {
  return (
    <div>
      <div>Subscription Details</div>
      <div>
        Monthly - 100INR per user
        <div>
          <button>get</button>
        </div>
      </div>

      <div>
        Yearly - 1000INR per user
        <div>
          <button>get</button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetails;
