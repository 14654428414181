import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { API } from "../../../api";
import "./index.css";

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const history = useHistory();

  useEffect(async () => {
    async function getJoinedGroups() {
      const { data } = await API.get("group", "get-joined");
      const joinedGroup = data[data.length - 1];
      return joinedGroup;
    }
    async function getLeaderboard(id) {
      if (id) {
        const { data, status } = await API.get(
          `group/get-user-data/${id}`,
          "?limit=5"
        );
        data?.sort((a, b) => {
          return b.points - a.points;
        });
        if (status === 200) setLeaderboard(data);
      }
    }
    const joinedGroup = await getJoinedGroups();
    getLeaderboard(joinedGroup?.group_id);
  }, []);

  return (
    <div className="m-4 p-4 column" style={{ backgroundColor: "#FFFFFF" }}>
      <div className="flex flex-row justify-between">
        <h6> Team Leaderboard</h6>
        <div
          className="body-extra-small-1 cursor-pointer"
          style={{ color: "#2288ee" }}
          onClick={() => history.push("/profile", { component: "team" })}
        >
          View All
        </div>
      </div>
      {(leaderboard?.length === 0 || !leaderboard) && (
        <div className="flex flex-row justify-center mt-8 items-center">
          You aren't a part of a team yet
        </div>
      )}
      {leaderboard?.map((row, index) => {
        return (
          <div className="flex flex-row justify-between m-4" key={index}>
            <div className="flex">
              <div></div>
              <div className="body-small-1 p-2">
                {row.user?.first_name} {row.user?.last_name}
              </div>
            </div>
            <div className="point-container">
              <p className="body-small-1 point-text p-2">{row.points} Points</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Leaderboard;
