import React from "react";
import { Switch, Route } from "react-router-dom";
import TeamAdmin from "./Admin";
import TeamLeaderboard from "./Leaderboard";
import ErrorPage from "../404RedirectPage";
import CreateTeam from "./CreateTeam";
import PrivateRoute from "../../routers/PrivateRoute";

const TeamRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/team/admin" component={TeamAdmin} />
      <PrivateRoute
        exact
        path="/team/leaderboard"
        component={TeamLeaderboard}
      />
      <PrivateRoute exact path="/team/create" component={CreateTeam} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default TeamRoutes;
