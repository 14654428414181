import "@mui/icons-material/index";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "../../../../api";
import { CircularProgress } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddUser from "@mui/icons-material/PersonAddAlt";
import AddMultipleUser from "@mui/icons-material/GroupAdd";
import { Alert } from "@mui/material";
import LinearButton from "../../../../components/Button";
import NewModal from "../../../../components/NewModal";
import { validateEmail } from "../../../../lib/helpers";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import MoveIcon from "@mui/icons-material/DriveFileMove";
import SendIcon from "@mui/icons-material/Send";
import { useStoreState } from "easy-peasy";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";

import BlankCSV from "../../../../assests/Files/Blank_CSV.csv";
import Papa from "papaparse";

const allowedExtensions = ["csv"];

const GroupMembers = () => {
  const userData = useStoreState((state) => state.user.data);
  const { role_id } = userData;
  const history = useHistory();
  const location = useLocation();
  const groupId = location.state.groupId;

  const [admin, setAdmin] = useState();
  const [team, setTeam] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupID, setGroupID] = useState();
  const [userEmail, setUserEmail] = useState("");

  const [file, setFile] = useState("");

  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [bulkEmail, setBulkEmail] = useState({
    user_email_1: "",
    user_email_2: "",
    user_email_3: "",
    user_email_4: "",
    user_email_5: "",
    user_email_6: "",
  });

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddBulkUserModal, setShowAddBulkUserModal] = useState(false);
  const [showCsvUploadModal, setShowCsvUploadModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showMoveUserModal, setShowMoveUserModal] = useState(false);
  const [showSendInviteModal, setShowSendInviteModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isButtonProgress, setIsButtonProgress] = useState(false);
  const [showAdminInGrid, setShowAdminInGrid] = useState(true);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const SendInvite = async () => {
    setIsButtonProgress(true);
    setMessage("");

    if (!groupID) {
      setSeverity("error");
      setMessage("Select a group from drop down.");
      setIsButtonProgress(false);
      return;
    }

    if (selectedRows.length < 1) {
      setSeverity("error");
      setMessage("Select At Least One Row.");
      setIsButtonProgress(false);
      return;
    }

    if (groupID == groupId) {
      setSeverity("error");
      setMessage("Can't send invites for the same group.");
      setIsButtonProgress(false);
      return;
    }

    try {
      const { data, status } = await API.post("group/send-bulk-invite", {
        current_group_id: groupId,
        new_group_id: groupID,
        user_email_list: selectedRows,
      });

      if (status === 200) {
        setSeverity("success");
        setMessage("Invitations sent successfully");
        getGroupsAndTeamMembers(groupId);
      } else {
        setSeverity("error");
        setMessage(data.error ? data.error.message : "Error Occurred");
      }
    } catch (error) {
      setSeverity("error");
      setMessage("An error occurred. Please try again later.");
    }
    setSelectedRows([]);
    setGroupID();
    setIsButtonProgress(false);
  };

  const MoveUser = async () => {
    setIsButtonProgress(true);
    setMessage("");

    if (!groupID) {
      setSeverity("error");
      setMessage("Select a group from drop down.");
      setIsButtonProgress(false);
      return;
    }

    if (selectedRows.length < 1) {
      setSeverity("error");
      setMessage("Select At Least One Row.");
      setIsButtonProgress(false);
      return;
    }

    if (groupID == groupId) {
      setSeverity("error");
      setMessage("Can't send invites for the same group.");
      setIsButtonProgress(false);
      return;
    }

    try {
      const { data, status } = await API.post("group/move-bulk-user", {
        current_group_id: groupId,
        new_group_id: groupID,
        user_email_list: selectedRows,
      });

      if (status === 200) {
        setSeverity("success");
        setMessage("Successfully Moved Users");
        getGroupsAndTeamMembers(groupId);
      } else {
        setSeverity("error");
        setMessage(data.error ? data.error.message : "Error Occurred");
      }
    } catch (error) {
      setSeverity("error");
      setMessage("An error occurred. Please try again later.");
    }
    setSelectedRows([]);
    setGroupID();
    setIsButtonProgress(false);
  };

  const RemoveUser = async () => {
    setIsButtonProgress(true);
    setMessage("");
    if (selectedRows.length < 1) {
      setSeverity("error");
      setMessage("Select At Least One Row.");
      setIsButtonProgress(false);
      return;
    }

    try {
      const { data, status } = await API.post("group/remove-bulk-user", {
        group_id: groupId,
        user_email_list: selectedRows,
      });

      if (status === 200) {
        setSeverity("success");
        setMessage("Successfully removed users");
        getGroupsAndTeamMembers(groupId);
      } else {
        setSeverity("error");
        setMessage(data.error ? data.error.message : "Error Occurred");
      }
    } catch (error) {
      setSeverity("error");
      setMessage("An error occurred. Please try again later.");
    }
    setSelectedRows([]);
    setIsButtonProgress(false);
  };

  const handleChange = (e) => {
    setUserEmail(e.target.value.toLowerCase());
  };

  const resetBulkEmail = () => {
    setBulkEmail({
      user_email_1: "",
      user_email_2: "",
      user_email_3: "",
      user_email_4: "",
      user_email_5: "",
      user_email_6: "",
    });
  };

  const blankExcelDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = BlankCSV;
    downloadLink.download = "BlankCsv.csv";
    downloadLink.click();
  };

  const handleCSVFileChange = (e) => {
    setMessage("");
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setSeverity("error");
        setMessage("Please input a csv file");
        setFile("");
        return;
      }
      setFile(inputFile);
    }
  };

  const _handleBulkEmailChange = (e, value) => {
    setBulkEmail({ ...bulkEmail, [e.target.name]: e.target.value });
  };

  const addSingleUser = async () => {
    const activateTimer = () => {
      setTimeout(() => {
        setMessage("");
      }, 6000);
    };

    setIsButtonProgress(true);
    if (!validateEmail(userEmail)) {
      setSeverity("error");
      setMessage("Please Enter Valid Email.");
      setIsButtonProgress(false);
      activateTimer();
      return;
    }

    try {
      const { data, status } = await API.post("group/add-user", {
        group_id: groupId,
        user_email_list: [userEmail],
      });

      if (status === 200) {
        setSeverity("success");
        setMessage("User added successfully");
        getGroupsAndTeamMembers(groupId);
        activateTimer();
      } else {
        setSeverity("error");
        setMessage(data.error ? data.error.message : "Error Occurred");
        activateTimer();
      }
    } catch (error) {
      setSeverity("error");
      setMessage("An error occurred. Please try again later.");
      activateTimer();
    }
    setUserEmail("");
    setIsButtonProgress(false);
  };

  const addBulkUser = async () => {
    const activateTimer = () => {
      setTimeout(() => {
        setMessage("");
      }, 6000);
    };

    setIsButtonProgress(true);
    const emailArray = Object.values(bulkEmail).filter(
      (email) => email.trim() !== ""
    );
    if (emailArray.length < 2) {
      setSeverity("error");
      setMessage("Enter At-least 2 fields.");
      setIsButtonProgress(false);
      activateTimer();
      return;
    }
    const uniqueEmailSet = new Set(emailArray);

    if (uniqueEmailSet.size !== emailArray.length) {
      setSeverity("error");
      setMessage("All emails should be unique.");
      setIsButtonProgress(false);
      activateTimer();
      return;
    }

    try {
      const { data, status } = await API.post("group/add-user", {
        group_id: groupId,
        user_email_list: emailArray,
      });

      if (status === 200) {
        setSeverity("success");
        setMessage("Users added successfully");
        getGroupsAndTeamMembers(groupId);
        activateTimer();
      } else {
        setSeverity("error");
        setMessage(data.error ? data.error.message : "Error Occurred");
        activateTimer();
      }
    } catch (error) {
      setSeverity("error");
      setMessage("An error occurred. Please try again later.");
      activateTimer();
    }
    resetBulkEmail();
    setIsButtonProgress(false);
  };

  const handleAddCsvData = async () => {
    setMessage("");
    setIsButtonProgress(true);

    if (!file) {
      setSeverity("error");
      setMessage("Enter a valid file");
      setIsButtonProgress(false);

      return;
    }

    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      try {
        const requiredHeaders = ["first_name", "last_name", "email"];
        const csv = Papa.parse(target.result, { header: true });
        const parsedData = csv?.data;
        const columns = parsedData.length > 0 ? Object.keys(parsedData[0]) : [];
        for (const requiredHeader of requiredHeaders) {
          if (!columns.includes(requiredHeader)) {
            setSeverity("error");
            setMessage('Required header "' + requiredHeader + '" is missing.');
            setFile("");
            setIsButtonProgress(false);

            return;
          }
        }
        for (const row of parsedData) {
          if (row.email === "") {
            setSeverity("error");
            setMessage("Email is missing in a row.");
            setFile("");
            setIsButtonProgress(false);
            return;
          }
        }
        try {
          const { data, status } = await API.post("group/csv-add", {
            group_id: groupId,
            user_list: parsedData,
          });

          if (status === 200) {
            setSeverity("success");
            setMessage("Users added successfully");
            getGroupsAndTeamMembers(groupId);
          } else {
            setSeverity("error");
            setMessage(data.error ? data.error.message : "Error Occurred");
          }
        } catch (error) {
          setSeverity("error");
          setMessage("An error occurred. Please try again later.");
        }
      } catch (error) {
        setSeverity("error");
        setMessage("Error parsing CSV file.");
      }
      setFile("");
      setIsButtonProgress(false);
    };

    reader.readAsText(file);
  };

  const getGroupsAndTeamMembers = async (id) => {
    const admin_id = parseInt(localStorage.getItem("id"), 10);
    setIsLoading(true);
    if (id) {
      const { data } = await API.get("group/user-status", id);
      setAdmin(data.admin);
      setTeam(data.users);
    }
    if (role_id === 2) {
      const { data } = await API.get("group/org-groups", admin_id);
      setGroups(data);
    } else {
      const { data } = await API.get("group", "get-org-groups");
      setGroups(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getGroupsAndTeamMembers(groupId);
  }, []);

  useEffect(() => {
    const headerData = ["Name", "Email", "Status", "Role"];
    const updatedColumns = headerData.map((headerText, index) => ({
      field: headerText.toLowerCase(),
      headerName: headerText,
      flex: 1,
      headerClassName: () => "bg-gray-100 border-b-2 border-gray-200",
    }));
    const updatedRows = team.map((member, index) => ({
      id: index,
      name: member?.user?.first_name
        ? member.user.first_name + " " + member?.user?.last_name
        : member.user.email.split("@")[0],
      email: member?.user?.email,
      status: member?.status?.toUpperCase(),
      role:
        member.user?.email === admin?.group_admin?.email ? "Admin" : "Member",
    }));
    setRows(updatedRows);
    setColumns(updatedColumns);
  }, [team]);

  useEffect(() => {
    const selectedEmails = rowSelectionModel
      .map((selectedId) => rows.find((item) => item.id === selectedId))
      .map(({ email }) => email);

    setSelectedRows(selectedEmails);
  }, [rowSelectionModel]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector /> */}
        <button
          onClick={() => setShowMoveUserModal(true)}
          className="flex space-x-1 items-center px-1 py-1 text-sm font-medium bg-white text-blue-500 hover:bg-blue-50 focus:outline-none transition-colors duration-200 rounded"
          style={{
            color: "#1976D2",
          }}
        >
          <svg className="w-5 h-5">
            <MoveIcon />
          </svg>
          <span>MOVE</span>
        </button>
        <button
          onClick={() => setShowDeleteUserModal(true)}
          className="flex space-x-1 items-center px-1 py-1 text-sm font-medium bg-white text-blue-500 hover:bg-blue-50 focus:outline-none transition-colors duration-200 rounded"
          style={{
            color: "#1976D2",
          }}
        >
          <svg className="w-5 h-5">
            <GroupRemoveIcon />
          </svg>
          <span>REMOVE</span>
        </button>
        <button
          onClick={() => setShowSendInviteModal(true)}
          className="flex space-x-1 items-center px-1 py-1 text-sm font-medium bg-white text-blue-500 hover:bg-blue-50 focus:outline-none transition-colors duration-200 rounded"
          style={{
            color: "#1976D2",
          }}
        >
          <svg className="w-5 h-5">
            <SendIcon />
          </svg>
          <span>INVITE</span>
        </button>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div
      className="min-h-content min-h-screen flex"
      style={{ backgroundColor: "#F9F9F9" }}
    >
      <div className="p-4 m-2 bg-white h-full h-screen w-full overflow-auto">
        <div className="flex justify-between">
          <div className="text-center my-4 text-gray-500 mt-0">
            <button
              onClick={() => {
                history.push("/administrator", {
                  component: "group",
                });
              }}
              className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
            >
              <svg
                className="w-6 h-6"
                style={{ color: "#2288EE", fill: "#2288EE" }}
                viewBox="0 0 20 20"
              >
                <ArrowBackIcon />
              </svg>
              <p className="text-gray-800">Back</p>
            </button>
          </div>
          <div className="flex text-center my-4 text-gray-500 mt-0">
            <button
              onClick={() => setShowAddUserModal(true)}
              className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
            >
              <svg
                className="w-6 h-6"
                style={{ color: "#2288EE", fill: "#2288EE" }}
                viewBox="0 0 20 20"
              >
                <AddUser />
              </svg>
              <p className="text-gray-800">Add a user</p>
            </button>

            <button
              onClick={() => setShowAddBulkUserModal(true)}
              className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
            >
              <svg
                className="w-6 h-6"
                style={{ color: "#2288EE", fill: "#2288EE" }}
                viewBox="0 0 20 20"
              >
                <AddMultipleUser />
              </svg>
              <p className="text-gray-800">Add multiple users</p>
            </button>
          </div>
          <div className="text-center my-4 text-gray-500 mt-0">
            {/* <button
              onClick={handleExport}
              className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer hover:bg-gray-100 hover:text-white transition-all duration-200"
            >
              <svg
                className="w-6 h-6"
                style={{ color: "#2288EE", fill: "#2288EE" }}
                viewBox="0 0 20 20"
              >
                <ExportIcon />
              </svg>
              <p className="text-gray-800">Export</p>
            </button> */}
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className="flex justify-center items-center h-screen">
              <CircularProgress size={80} />
            </div>
          ) : team.length ? (
            <DataGrid
              rows={rows}
              columns={columns}
              keyField="id"
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              slots={{
                toolbar: () => <CustomToolbar />,
              }}
              isRowSelectable={(params) => params.row.role !== "Admin"}
              sx={{
                "& .MuiDataGrid-columnHeaderCheckbox": {
                  backgroundColor: "#F3F4F6",
                  borderBottom: "2px solid #e5e7eb",
                },
              }}
            />
          ) : (
            <div class="flex justify-center items-start min-h-screen bg-white ">
              <div class="text-center p-8 bg-white-200 rounded-t-lg shadow-md w-full md:w-1/2">
                <p class="text-3xl font-bold text-gray-500 mb-4">
                  No Users in this Group
                </p>
                <p class="text-gray-700">
                  It looks like there are no users in this group at the moment.
                </p>
                <p class="text-gray-700">
                  Feel free to invite some users to join!
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {showAddUserModal && (
        <NewModal
          heading={<div className="block text-center">New User Email</div>}
          body={
            <div className="mx-16">
              <div className="w-full">
                <label className="block mb-1 text-sm font-medium text-gray-600">
                  User Email
                </label>
                <input
                  className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                  type="text"
                  placeholder="abc@linear.live"
                  value={userEmail}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full mt-4">
                <LinearButton
                  onClick={addSingleUser}
                  text={
                    isButtonProgress ? <CircularProgress size={30} /> : "Submit"
                  }
                />
              </div>

              <div className="mt-6 flex justify-center inline-block ">
                {message && message !== "" && (
                  <Alert severity={severity}>{message}</Alert>
                )}
              </div>
            </div>
          }
          onClose={() => {
            setShowAddUserModal(false);
          }}
        />
      )}
      {showAddBulkUserModal && (
        <NewModal
          heading={<div className="block text-center">User Emails</div>}
          body={
            <div className="mx-16">
              <div className="flex">
                <div className="flex space-x-4">
                  <div className="w-full">
                    <label className="block mb-1 text-sm font-medium text-gray-600">
                      1. Email
                    </label>
                    <input
                      className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                      type="text"
                      placeholder="abc@linear.live"
                      name="user_email_1"
                      value={bulkEmail.user_email_1}
                      onChange={_handleBulkEmailChange}
                    />
                  </div>
                  <div className="w-full">
                    <label className="block mb-1 text-sm font-medium text-gray-600">
                      2. Email
                    </label>
                    <input
                      className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                      type="text"
                      placeholder="abc@linear.live"
                      name="user_email_2"
                      value={bulkEmail.user_email_2}
                      onChange={_handleBulkEmailChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="flex space-x-4">
                  <div className="w-full">
                    <label className="block mb-1 text-sm font-medium text-gray-600">
                      3. Email
                    </label>
                    <input
                      className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                      type="text"
                      placeholder="abc@linear.live"
                      name="user_email_3"
                      value={bulkEmail.user_email_3}
                      onChange={_handleBulkEmailChange}
                    />
                  </div>
                  <div className="w-full">
                    <label className="block mb-1 text-sm font-medium text-gray-600">
                      4. Email
                    </label>
                    <input
                      className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                      type="text"
                      placeholder="abc@linear.live"
                      name="user_email_4"
                      value={bulkEmail.user_email_4}
                      onChange={_handleBulkEmailChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="flex space-x-4">
                  <div className="w-full">
                    <label className="block mb-1 text-sm font-medium text-gray-600">
                      5. Email
                    </label>
                    <input
                      className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                      type="text"
                      placeholder="abc@linear.live"
                      name="user_email_5"
                      value={bulkEmail.user_email_5}
                      onChange={_handleBulkEmailChange}
                    />
                  </div>
                  <div className="w-full">
                    <label className="block mb-1 text-sm font-medium text-gray-600">
                      6. Email
                    </label>
                    <input
                      className="w-full px-3 py-2 rounded-md border border-gray-300 outline-none focus:ring focus:ring-blue-400 transition-all duration-300"
                      type="text"
                      placeholder="abc@linear.live"
                      name="user_email_6"
                      value={bulkEmail.user_email_6}
                      onChange={_handleBulkEmailChange}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full mt-4">
                <LinearButton
                  onClick={addBulkUser}
                  text={
                    isButtonProgress ? <CircularProgress size={30} /> : "Submit"
                  }
                />
              </div>

              <div className="mt-6 flex justify-center inline-block ">
                {message && message !== "" && (
                  <Alert severity={severity}>{message}</Alert>
                )}
              </div>

              <div className="flex space-x-2 justify-center">
                <p
                  onClick={() => {
                    setShowCsvUploadModal(true);
                    setShowAddBulkUserModal(false);
                  }}
                  className="block mt-2 text-sm font-medium text-blue-600 cursor-pointer"
                >
                  Upload csv
                </p>
                <p className="block mt-2 text-sm font-medium text-blue-600">
                  or
                </p>
                <p
                  onClick={blankExcelDownload}
                  className="block mt-2 text-sm font-medium text-blue-600 cursor-pointer"
                >
                  Download a blank csv
                </p>
              </div>
            </div>
          }
          onClose={() => {
            setShowAddBulkUserModal(false);
          }}
        />
      )}
      {showCsvUploadModal && (
        <NewModal
          heading={<div className="block text-center">UPLOAD CSV </div>}
          body={
            <div className="mx-16">
              <div className="block my-4 text-gray-500 mt-0">
                <input onChange={handleCSVFileChange} name="file" type="file" />
              </div>

              <div className="mt-4">
                <LinearButton
                  onClick={handleAddCsvData}
                  text={
                    isButtonProgress ? <CircularProgress size={30} /> : "Submit"
                  }
                />
              </div>

              <div className="mt-6 flex justify-center">
                {message && message !== "" && (
                  <Alert severity={severity}>{message}</Alert>
                )}
              </div>
            </div>
          }
          onClose={() => {
            setMessage("");
            setShowCsvUploadModal(false);
            setFile("");
          }}
        />
      )}
      {showDeleteUserModal && (
        <NewModal
          heading={<div className="block text-center">Remove Users👦</div>}
          body={
            <div className="flex flex-col mx-16">
              <div className="block text-center my-4 text-gray-500 mt-0">
                <span>
                  Are You Sure you want to remove {selectedRows.length} user?
                </span>
              </div>

              <div className="flex space-x-4 justify-center">
                <div className="mt-4">
                  <LinearButton
                    onClick={RemoveUser}
                    text={
                      isButtonProgress ? <CircularProgress size={30} /> : "Yes"
                    }
                  />
                </div>
                <div className="mt-4 ">
                  <LinearButton
                    onClick={() => {
                      setShowDeleteUserModal(false);
                      setMessage("");
                    }}
                    text={"No"}
                  />
                </div>
              </div>

              <div className="mt-6 flex justify-center">
                {message && message !== "" && (
                  <Alert severity={severity}>{message}</Alert>
                )}
              </div>
            </div>
          }
          onClose={() => {
            setMessage("");
            setShowDeleteUserModal(false);
            // selectedRows = [];
          }}
        />
      )}
      {showMoveUserModal && (
        <NewModal
          heading={<div className="block text-center">Move Users👦</div>}
          body={
            <div className="mx-16">
              <div className="flex flex-row space-x-4 my-4 text-gray-500 mt-0">
                <span className="mt-1">Select the group</span>
                <div className="">
                  <select
                    onChange={(e) => {
                      setGroupID(e.target.value);
                    }}
                    className="input-box body-small-1"
                  >
                    <option value="">Select an option</option>
                    {groups.map((group, index) => {
                      return (
                        <option value={group?.id} key={index}>
                          {group?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="mt-8 justify-center">
                <LinearButton
                  onClick={MoveUser}
                  text={
                    isButtonProgress ? <CircularProgress size={30} /> : "Move"
                  }
                />
              </div>

              <div className="mt-6 flex justify-center">
                {message && message !== "" && (
                  <Alert severity={severity}>{message}</Alert>
                )}
              </div>
            </div>
          }
          onClose={() => {
            setMessage("");
            setShowMoveUserModal(false);
            setGroupID();
          }}
        />
      )}
      {showSendInviteModal && (
        <NewModal
          heading={<div className="block text-center">Send Invitation 📨</div>}
          body={
            <div className="mx-16">
              <div className="flex flex-row space-x-4 my-4 text-gray-500 mt-0">
                <span className="mt-1">Select the group</span>
                <div className="">
                  <select
                    onChange={(e) => {
                      setGroupID(e.target.value);
                    }}
                    className="input-box body-small-1"
                  >
                    <option value="">Select an option</option>
                    {groups.map((group, index) => {
                      return (
                        <option value={group?.id} key={index}>
                          {group?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="mt-8 justify-center">
                <LinearButton
                  onClick={SendInvite}
                  text={
                    isButtonProgress ? <CircularProgress size={30} /> : "Invite"
                  }
                />
              </div>

              <div className="mt-6 flex justify-center">
                {message && message !== "" && (
                  <Alert severity={severity}>{message}</Alert>
                )}
              </div>
            </div>
          }
          onClose={() => {
            setMessage("");
            setShowSendInviteModal(false);
            setGroupID();
          }}
        />
      )}
    </div>
  );
};

export default GroupMembers;
