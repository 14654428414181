import React from "react";
import "./index.css";

const ReviewMCQ = (props) => {
  const { options, selectedButton } = props;
  if (!options) return null;

  return (
    <div>
      <div>
        <div className="grid md:grid-cols-2 gap-4 sm:grid-cols-1 mb-4 flex-wrap">
          {options.map((option, index) => {
            return (
              <button
                className="option body-medium-1 px-6 py-3 my-2"
                key={index}
                style={{
                  backgroundColor:
                    option.mcq_option.id == selectedButton
                      ? option.mcq_option.is_correct
                        ? "#09B746"
                        : "#C82020"
                      : option.mcq_option.is_correct
                      ? "#09B746"
                      : null,
                  color:
                    option.mcq_option.id == selectedButton
                      ? option.mcq_option.is_correct
                        ? "white"
                        : "white"
                      : option.mcq_option.is_correct
                      ? "white"
                      : "black",
                }}
              >
                {option?.mcq_option?.option_text}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default ReviewMCQ;
