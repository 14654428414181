import { ReactComponent as Logo } from "../../assests/icons/logos/2.svg";
import useScreenSize from "../../hooks/useScreenSize";
const LHS = () => {
  const { isMobile } = useScreenSize();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "var(--prussian-blue)",
        display: isMobile ? "none" : "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Logo title="Logo" width="15.375rem" height="5.25rem" />
      {/* Original Values */}{" "}
      {/*<Logo title="Logo" width="10.375rem" height="3.25rem"/> */}
      <div style={{ marginLeft: "3.4375rem" }}>
        <h3 className="text-color-white">Learn Better at Work</h3>
        <p
          className="text-color-white body-small-1"
          style={{ marginTop: "21px" }}
        >
          Increase the learning retention and application ability of your teams.
        </p>
        <p className="text-color-white body-small-1">
          Fun and Active. No Boring Videos. No Irrelevant Certifications.
        </p>
        <p className="text-color-white body-small-1">
          Personalized. Gamified. The Machine Learns!
        </p>
      </div>
    </div>
  );
};

export default LHS;
