import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../../api";
import NewModal from "../../../components/NewModal";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./styles.css";
import LinearButton from "../../../components/Button";
import useScreenSize from "../../../hooks/useScreenSize";

const ScrollAssessment = (props) => {
  const [assessmentQuestions, setAssessmentQuestions] = useState();
  const radioRef = useRef();
  const { isMobile } = useScreenSize();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    defaultAnimation: {
      duration: 1200,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const [assessmentResponse, setAssessmentResponse] = useState();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const { program_id } = props.match.params;
  const { program_type } = props.location.state
  useEffect(() => {
    async function getAssessmentQuestions() {
      const { data } = await API.get(
        "strapi-assessment/assessment-question",
        program_id
      );
      setAssessmentQuestions(data);
    }
    console.log({ program_type });
    if (program_type === "program") {
      getAssessmentQuestions();
    } else {
      history.push({
        pathname: `/assessment/result/${program_id}`,
        state: {
          program_type: "sprint",
        }
      })
    }

  }, []);

  const _handleChange = (e) => {
    setAssessmentResponse({
      ...assessmentResponse,
      [e.target.name]: e.target.value,
    });
  };

  const redirect = (status) => {
    if (status === 200) {
      setShowModal(false);
      history.push({
        pathname: `/assessment/result/${program_id}`,
        state: {
          program_type: "program",
        }
      })    } else {
      setShowModal(false);
    }
  }

  const _handleSubmit = async () => {
    setShowModal(true);
    // Check if assessment-response exists
    const { data, status: getStatus } = await API.get("strapi-assessment/assessment-responses", program_id);
    if (getStatus === 200) {
      if (Array.isArray(data) && data.length !== 0) {
        // PATCH existing value
        const { status } = await API.patch("strapi-assessment/assessment-response", {
          program_id,
          user_response_list: assessmentResponse,
        });
        redirect(status);
      } else {
        const { status } = await API.post("strapi-assessment/assessment-response", {
          program_id,
          user_response_list: assessmentResponse,
        });
        redirect(status);
      }
    }

  };

  if (!assessmentQuestions || assessmentQuestions.length === 0) return null;

  return (
    <div className="navigation-wrapper">
      <div
        ref={sliderRef}
        className="keen-slider"
        style={{ height: isMobile ? "80vh" : "85vh" }}
      >
        {assessmentQuestions.map((question, index) => {
          return (
            <div className="keen-slider__slide number-slide1 px-8">
              <div className="flex flex-col">
                <h5 className="text-2xl my-4">{question.stem}</h5>
                {question.components?.map((option, index) => {
                  return (
                    <div
                      className="body-medium-1 py-3 px-8 flex flex-row items-center gap-3"
                      style={{
                        border: "1px solid #aaa",
                        borderRadius: 10,
                        margin: 10,
                      }}
                    >
                      <input
                        type="radio"
                        name={question.id}
                        ref={radioRef}
                        value={option?.options?.id}
                        onChange={_handleChange}
                        onClick={(e) =>
                          setTimeout(() => {
                            e.stopPropagation() || instanceRef.current?.next();
                          }, 700)
                        }
                        disabled={
                          currentSlide ===
                          instanceRef.current?.track?.details.slides.length
                        }
                        id={option?.options?.id}
                        className="cursor-pointer"
                        style={{ height: "20px", width: "20px" }}
                      />

                      <label
                        for={option?.options?.id}
                        className="cursor-pointer"
                      >
                        {"  "}
                        {option?.options?.option_text}
                      </label>
                    </div>
                  );
                })}
                <div className="self-center mt-8">
                  {currentSlide ===
                    instanceRef.current?.track?.details.slides.length - 1 && (
                      <LinearButton onClick={_handleSubmit} text="Submit" />
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
      )}
      {loaded && instanceRef.current && (
        <>
          <Arrow
            left
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
            disabled={currentSlide === 0}
          />

          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
            disabled={
              currentSlide ===
              instanceRef.current.track.details.slides.length - 1
            }
          />
        </>
      )}

      <div>
        {showModal && (
          <NewModal
            body={
              <div className="flex flex-col gap-4 items-center justify-center">
                <div>
                  <CircularProgress />
                </div>
                <div className="text-center">
                  <h5>Please wait while we are assessing your persona...</h5>
                </div>
              </div>
            }
            hasCloseButton={false}
          />
        )}
      </div>
    </div>
  );
};

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? "arrow--left" : "arrow--right"
        } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

export default ScrollAssessment;
