import React, { useState, useEffect } from "react";
import "./index.css";

const MCQ = (props) => {
  const { options, isUserCorrect, correctAnswer } = props;
  const [selectedButton, setSelectedButton] = useState();
  const [answer, setAnswer] = useState();
  //const [alignVertical, setAlignVertical] = useState(false);

  // useEffect(() => {
  //   for (const option of options) {
  //     if (option.option_text.length > 50) setAlignVertical(true);
  //   }
  //   return () => {
  //     setAlignVertical(false);
  //   };
  // }, [options]);

  // const selectMcqResponse = (option_id, key) => {
  //   setAnswer(option_id);
  //   setSelectedButton(key);
  // };

  // useEffect(() => {
  //   handleMcqResponse();
  // }, [answer]);

  // useEffect(() => {
  //   setSelectedButton(null);
  //   setAnswer(null);
  // }, [props.id]);

  // const handleMcqResponse = async () => {
  //   const mcqResponse = {
  //     answer,
  //     type: "mcq",
  //   };
  //   console.log(mcqResponse);
  //   props.sendResponse(mcqResponse);
  // };

  if (!options) return null;

  return (
    <div>
      <div>
        <div className="grid md:grid-cols-2 gap-4 sm:grid-cols-1 mb-8 flex-wrap">
          {options.map((option, index) => {
            return (
              <button
                className="option body-medium-1 p-6 my-4"
                key={index}
                style={{
                  // backgroundColor:
                  //   index === selectedButton ? "#00aacc" : "#bbeeff",
                  backgroundColor:
                    isUserCorrect !== undefined
                      ? options[index]?.mcq_option?.id === correctAnswer?.id
                        ? "#09B746" //green
                        : index === selectedButton
                        ? "#C82020" //red
                        : "#ffffff" //non selected color
                      : index === selectedButton
                      ? "#00aacc" //selected color
                      : "#ffffff", //non selected color
                }}
                // onClick={() =>
                //   // selectMcqResponse(options[index]?.mcq_option?.id, index)
                // }
              >
                {option?.mcq_option?.option_text}
              </button>
            );
          })}
        </div>

        {/* <button
          className="option"
          id="a"
          style={{
            backgroundColor: id === selectedButton ? "white" : "blue",
          }}
          onClick={() => selectMcqResponse(options[0]?.id, "a")}
        >
          {options[0]?.option_text}
        </button>
        <button
          className="option"
          id="b"
          style={{
            backgroundColor: id === selectedButton ? "white" : "blue",
          }}
          onClick={() => selectMcqResponse(options[1]?.id, "b")}
        >
          {options[1]?.option_text}
        </button>
        <button
          className="option"
          id="c"
          style={{
            backgroundColor: id === selectedButton ? "white" : "blue",
          }}
          onClick={() => selectMcqResponse(options[2]?.id, "c")}
        >
          {options[2]?.option_text}
        </button>
        <button
          className="option"
          id="d"
          style={{
            backgroundColor: id === selectedButton ? "white" : "blue",
          }}
          onClick={() => selectMcqResponse(options[3]?.id, "d")}
        >
          {options[3]?.option_text}
        </button> */}
      </div>
    </div>
  );
};
export default MCQ;
