import React from "react";
import LinearButton from "../../../components/Button";

const InvitationTable = ({ pendingInvites, handleClick = () => {} }) => {
  return (
    <table className="w-full rounded-lg shadow">
      <thead className="bg-gray-50 border-b-2 border-gray-200">
        <tr>
          <th className="p-3 text-sm font-semibold tracking-wider text-left">
            Group Name
          </th>
          <th className="p-3 text-sm font-semibold tracking-wider text-left">
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-100 whitespace-nowrap">
        {pendingInvites.map((invite) => (
          <tr key={invite.id}>
            <td className="p-3 text-sm text-gray-700">
              {invite.groups[0].name}
            </td>
            <td className="p-3 text-sm text-gray-700">
              <LinearButton
                onClick={() => handleClick(invite.group_id)}
                text={"Accept Invite"}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InvitationTable;
