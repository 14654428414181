import React, { useEffect, useState } from "react";
import ContextCardRowBox from "./ContextCardRowBox";
import { CircularProgress } from "@material-ui/core";
import { API } from "../../../api";
import ActivityRowBox from "../ActivityRowBox";

const ContextCardList = (props) => {
  const [contextCardList, setContexCardList] = useState([]);
  const [weekendFlag, setWeekendFalg] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getContextCardData = async () => {
    setContexCardList([]);
    setIsLoading(true);
    try {
      const { data } = await API.get("context-card", props.dayAdjust);
      setContexCardList(data.contextCard);
      setWeekendFalg(data.isWeekend);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getContextCardData();
  }, [props.dayAdjust]);

  return (
    <div>
      {/* {isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )} */}
      {isLoading && null}

      {/* if Weekend return nothing*/}
      {weekendFlag && null}

      {/* {if context card available then render them} */}
      {contextCardList?.map((card, index) => (
        <ContextCardRowBox key={index} context_cards={card.context_cards} />
      ))}
    </div>
  );
};

export default ContextCardList;
