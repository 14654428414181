import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import ReviewQuizContainer from "../../Activity/StrapiGetActivity/CompletedActivity/ReviewQuiz";

const ReviewRevision = (props) => {
  const location = useLocation();
  const { revision } = props.match.params;
  const goal_id = location.state?.goal_id;

  const fetchUrl = `revision/user-responses?revision_id=${revision}&goal_id=${goal_id}&attempt=${1}`

  return (
    <ReviewQuizContainer
      fetchURL={fetchUrl}
    />
  )

};

export default ReviewRevision;