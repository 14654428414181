import React, { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { API } from "../../../../api";
import Alert from "@material-ui/lab/Alert";

const PROFICIENY_LEVEL = [
  { value: "beginner", text: "Beginner" },
  { value: "intermediate", text: "Intermediate" },
  { value: "master", text: "Master" },
];

const AdminAddProgram = () => {
  const [programData, setProgramData] = useState({
    code: "",
    name: "",
    description: "",
    track_id: "",
    number_of_weeks: "",
    proficiency: "beginner",
    program_level: "",
  });
  const [trackList, setTrackList] = useState();

  const [inProgress, toggleProgress] = useState(false);
  const [alertVisible, toggleAlertVisibility] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    async function getTrackList() {
      const { data } = await API.get("track", "all");
      setTrackList([" ", ...data]);
    }
    getTrackList();
  }, []);

  const _handleChange = (e) => {
    setProgramData({ ...programData, [e.target.name]: e.target.value });
    console.log(programData);
    toggleAlertVisibility(false);
  };

  const _handleSubmit = async () => {
    try {
      toggleProgress(true);
      const { data, status } = await API.post("program/add", programData);
      if (status === 200) {
        setAlertMessage("Program has been added");
        setAlertSeverity("info");
        toggleAlertVisibility(true);
        toggleProgress(false);
      } else {
        setAlertMessage(data.error ? data.error.message : "Error Occured");
        setAlertSeverity(data.error ? "error" : "info");
        toggleAlertVisibility(true);
        toggleProgress(false);
      }
    } catch (err) {
      setAlertMessage("An error occured. Please try again later.");
      setAlertSeverity("error");
      toggleAlertVisibility(true);
      toggleProgress(false);
    }
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="shadow-xl p-36">
        <div className="text-center text-3xl">Admin Dashboard</div>
        <div
          style={{ marginTop: "10%", display: "flex", flexDirection: "column" }}
        >
          <h5>Add a new Program </h5>
          <TextField
            id="outlined-basic"
            label="Code"
            variant="outlined"
            name="code"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            name="name"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            name="description"
            multiline={true}
            rows={5}
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px", lineHeight: 1.2 } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Number of Weeks"
            variant="outlined"
            name="number_of_weeks"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Program Level"
            variant="outlined"
            name="program_level"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <select
            style={{ marginBottom: "20px", padding: "20px", fontSize: "15px" }}
            onChange={_handleChange}
            name="proficiency"
          >
            {PROFICIENY_LEVEL.map((proficiency, index) => {
              return (
                <option key={index} value={proficiency.value}>
                  {proficiency.text}
                </option>
              );
            })}
          </select>
          <InputLabel
            style={{
              fontFamily: "ClanOT-NarrowNews",
              fontSize: "20px",
              marginBottom: "20px",
            }}
          >
            Select Track
          </InputLabel>
          <select className="p-4 mb-4" onChange={_handleChange} name="track_id">
            {trackList?.map((track, index) => {
              return (
                <option key={index} value={track.id}>
                  {track.name}
                </option>
              );
            })}
          </select>
          <Button
            color="primary"
            variant="contained"
            onClick={_handleSubmit}
            style={{ padding: "20px" }}
            disabled={inProgress}
          >
            {inProgress ? <CircularProgress /> : "Add Program"}
          </Button>
          {alertVisible && (
            <Alert style={{ marginTop: "1.5rem" }} severity={alertSeverity}>
              {alertMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAddProgram;
