import React, { useEffect, useState } from "react";
import { API } from "../../api";
import LinearButton from "../../components/Button";
import LinearTextArea from "../../components/TextArea";
import LinearTextBox from "../../components/TextBox";
import useSWR from "swr";

const Support = () => {
  const [supportIssueType, setSupportIssueType] = useState("");
  const [supportIssueTopic, setSupportIssueTopic] = useState("");
  const [supportSubject, setSupportSubject] = useState("");
  const [supportSubjectErrorMsg, setSupportSubjectErrorMsg] = useState("");
  const [supportBody, setSupportBody] = useState("");
  const [supportBodyErrorMsg, setSupportBodyErrorMsg] = useState("");
  const [message, setMessage] = useState("");

  const { data: issueList } = useSWR("support/get-issue-list");

  const handleSupportSubject = (e) => {
    setMessage("");
    if (e.target.value === "") {
      setSupportSubjectErrorMsg("Support Title is required");
    } else {
      setSupportSubjectErrorMsg("");
    }
    setSupportSubject(e.target.value);
  };

  const handleSupportBody = (e) => {
    setMessage("");
    if (e.target.value === "") {
      setSupportBodyErrorMsg("Support Body is required");
    } else {
      setSupportBodyErrorMsg("");
    }
    setSupportBody(e.target.value);
  };

  const handleSubmit = async () => {
    const { status } = await API.post("support/create", {
      issue_type_id: supportIssueType,
      issue_topic_id: supportIssueTopic,
      subject: supportSubject,
      body: supportBody,
    });
    if (status === 200) setMessage("Sent successfully");
    else setMessage("An error occured");
  };

  return (
    <div className="flex flex-col justify-center gap-4 items-center h-screen">
      <h4>Linear Support</h4>
      <div>Contact us!</div>
      <div className="flex gap-4">
        <select
          onChange={(e) => setSupportIssueType(e.target.value)}
          className="input-box body-small-1"
        >
          <option value="" selected>
            Select Issue Type
          </option>
          {issueList?.issueTypeList?.map((issueType, index) => {
            return (
              <option value={issueType.id} key={index}>
                {issueType.issue_type_text}
              </option>
            );
          })}
        </select>
        <select
          onChange={(e) => setSupportIssueTopic(e.target.value)}
          className="input-box body-small-1"
        >
          <option value="" selected>
            Select Issue Topic
          </option>

          {issueList?.issueTopicList?.map((issueTopic, index) => {
            return (
              <option value={issueTopic.id} key={index}>
                {issueTopic.issue_topic_text}
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <LinearTextBox
          label=""
          errorMessage={supportSubjectErrorMsg}
          onChange={handleSupportSubject}
          placeholder="Please tell us the subject"
        />
      </div>
      <div>
        <LinearTextArea
          label=""
          errorMessage={supportBodyErrorMsg}
          onChange={handleSupportBody}
          placeholder="Please tell us about the problem in detail"
          rows={5}
          columns={36}
          value={supportBody}
        />
      </div>
      <span>
        Please check our <a style={{"color": "var(--link-color)"}} href="/Linear_User_Guide.pdf" download="Linear_User_Guide.pdf" type="application/pdf">UserGuide</a>.
      </span>
      <div>
        <LinearButton text="Submit" onClick={handleSubmit} />
      </div>
      {message}
    </div>
  );
};

export default Support;
