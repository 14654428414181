import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../../404RedirectPage";
import AdminAddSubtopic from "./Add";
import AdminSubtopicList from "./List";

const AdminSubtopicRoutes = () => {
  return (
    <Switch>
      <Route exact path="/admin/subtopic/add" component={AdminAddSubtopic} />
      <Route exact path="/admin/subtopic/list" component={AdminSubtopicList} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default AdminSubtopicRoutes;
