import React, { useState, useEffect } from "react";
import { API } from "../../../../api";

const AdminGoalList = () => {
  const [goalList, setGoalList] = useState();

  useEffect(() => {
    async function getGoalList() {
      const { data } = await API.get("goal", "all");
      setGoalList(data);
    }
    getGoalList();
  }, []);

  return (
    <div>
      {goalList?.map((goal, index) => {
        return (
          <div key={index} className="p-4 shadow-xl m-4 ">
            <div className="text-2xl">{goal.name}</div>
            <div>
              <span className="text-xl text-blue-700">Description:</span>{" "}
              {goal.description}
            </div>
            <div>
              <div>
                <span className="text-xl text-blue-700">
                  Topics Under this goal:
                </span>{" "}
              </div>
              {goal.goal_topic_maps?.map((topic, index) => {
                return <div key={index}>{topic.topic?.name}</div>;
              })}
            </div>
            <div>
              <span className="text-xl text-blue-700">Status:</span>{" "}
              {goal.active ? "Active" : "Inactive"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AdminGoalList;
