import React from "react";

const Reading = ({image="", id}) => {

  if (!image) return null;
  // We are just displaying image here
  
  return (
    <div>
        <div className="option body-medium-1 px-6 py-3 my-2" 
        style={{
          display:"flex",
          justifyContent:"center", /* horizontally center */
          alignItems:"center",    /* vertically center */
          }} >
          <img src={`https://content.linear.live${image}`} alt="flashcard" 
          height={150} width={700} />
        </div>
    </div>
  );
};

export default Reading;