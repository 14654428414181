import React, { useEffect, useState } from "react";
import MyPrograms from "../MyPrograms";
import ExplorePrograms from "..";
import { useHistory } from "react-router-dom";
import "./index.css";

import profile from "../../../assests/icons/profile/profile.svg";
import journal from "../../../assests/icons/profile/journal.svg";

import selected_profile from "../../../assests/icons/profile/selected_profile.svg";
import selected_journal from "../../../assests/icons/profile/selected_journal.svg";
import { API } from "../../../api";
import ProgramScreenMobile from "./Mobile";
import Table from "../../../components/Table";
import TourScreen from "../../../components/TourScreen";

const MENU_OPTIONS = [
  {
    index: 0,
    text: "My Programs",
    value: "my_programs",
    img: profile,
    selected_img: selected_profile,
  },
  {
    index: 1,
    text: "Explore",
    value: "explore",
    img: journal,
    selected_img: selected_journal,
  },
];

const MENU_INDEX = ["my_programs", "explore"];

const ProgramScreen = ({ location }) => {
  const dataLength = location.state && location.state.dataLength;
  const [component, setComponent] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  // const [userProgramList, setUserProgramList] = useState([]);
  const [userGoalList, setUserGoalList] = useState([]);

  const getEnrolled = async () => {
    // const { data: enrolledPrograms, status: peStatus } = await API.get("program", "get-enrolled");
    const { data: enrolledGoals, status: egStatus } = await API.get(
      "goal",
      "enrolled-goals"
    );

    if (egStatus === 200) {
      if (enrolledGoals.length >= 1) {
        setComponent("my_programs");
        setSelectedButton(0);
      } else if (enrolledGoals?.length === undefined) {
        setComponent(null);
        setSelectedButton(null);
      } else {
        setComponent("explore");
        setSelectedButton(1);
      }
      /* First we find all the goals that are not quitted, then we extract programs from them. 
        Since multiple goals are under a single program, we are removing duplicates if any in the reducer */
      const nonQuittedGoals = enrolledGoals.filter((d) => d.quitted === false);
      const nonQuittedPrograms = nonQuittedGoals.reduce((acc, cur) => {
        if (acc.findIndex((d) => d.id === cur.goal.program.id) === -1) {
          acc.push(cur.goal.program);
        }
        return acc;
      }, []);
      setUserGoalList(nonQuittedPrograms);
    }
  };

  useEffect(() => {
    getEnrolled();
  }, []);

  const displayComponent = () => {
    if (component === "my_programs") {
      if (userGoalList.length === 0) {
        return <div>The programs you are enrolled in appears here.</div>;
      }
      return <MyPrograms />;
    } else if (component === "explore") {
      return <ExplorePrograms enrolledGoals={userGoalList} />;
    }
  };

  if (window.screen.width < 420) {
    return <ProgramScreenMobile />;
  }

  return (
    <div
      className="min-h-content min-h-screen"
      style={{ backgroundColor: "#F9F9F9" }}
    >
      <div className="flex flex-row">
        <div className="p-4 m-4 bg-white h-full container w-1/3 lg:w-1/5">
          <div>
            {MENU_OPTIONS.map((option, index) => {
              return (
                <div
                  key={index}
                  className="body-small-1 p-4 mb-4 cursor-pointer"
                  onClick={() => {
                    setComponent(option.value);
                    setSelectedButton(index);
                  }}
                  style={
                    index === selectedButton
                      ? {
                          backgroundColor: "#E9F3FD",
                          color: "#2288EE",
                          borderRadius: "10px",
                        }
                      : null
                  }
                >
                  <div className="flex flex-row items-center gap-4">
                    <img
                      alt="menu_option_img"
                      src={
                        selectedButton === index
                          ? option.selected_img
                          : option.img
                      }
                    />
                    {option.text}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="lg:w-4/5 md:w-2/3 p-4 m-4 bg-white h-full container overflow-auto">
          {displayComponent()}
        </div>
      </div>
      {dataLength !== undefined && dataLength === 0 && <TourScreen />}
    </div>
  );
};

export default ProgramScreen;
