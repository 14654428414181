import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";

let interval;
export default function Toast({
  title,
  text,
  actionButtonText,
  actionButtonFunction,
}) {
  const [progress, setProgress] = useState(100);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(true);
    interval = setInterval(() => {
      setProgress((prev) => prev - 0.09);
    }, 10);
  }, []);

  useEffect(() => {
    if (progress < 1) {
      clearInterval(interval);
      setShowToast(false);
    }
  }, [progress]);

  return (
    <div
      className={`fixed right-10 top-10 w-5/12 lg:w-3/12 min-h-content bg-white rounded-lg shadow-xl hidden 
       md:block
      `}
      style={{
        zIndex: 9999,
        transform: showToast ? "translateX(0)" : "translateX(120%)",
        transition: "all 250ms linear",
      }}
    >
      <div className="p-4">
        <div className="flex flex-row justify-between items-center">
          <div className="uppercase font-semibold">{title}</div>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => setShowToast(false)}
          />
        </div>
        <div>{text}</div>
        <button
          onClick={actionButtonFunction}
          className="uppercase text-sm tracking-wider text-blue-700 bg-blue-100 mt-4 hover:bg-blue-200 duration-250 ease-in-out rounded-lg p-1.5"
        >
          {actionButtonText}
        </button>
      </div>

      <div
        className="h-2 absolute bottom-0 bg-blue-300 rounded-lg"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
}
