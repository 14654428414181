import React from "react";

const FlashCard = ({image, id}) => {

  if (!image) return null;
  // We are just displaying image here

  return (
    <div className="option body-medium-1 px-6 py-3 my-2"
    style={{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      }}>
      <img src={`https://content.linear.live${image}`} alt="flashcard" 
        height={150} width={700} />
    </div>
  );
};

export default FlashCard;